<template>
	<div>
		<!--about-us start -->
			<div style="position: relative;">
				<video src="../assets/videos/background.mp4" loop autoplay style="width: 100%; left: 0px; position: absolute;"></video>
			
			<section id="home" class="about-us" >
				<div class="container">
					<div class="about-us-content">
						<div class="row">
							<div class="col-sm-12 container text-center" style="max-width: 900px;">
								<div class="" >
									<img src="../../public/images/home/bism-img1-white.png" class="mb-md-3 mt-2">
									<h2 class="mb-4">{{$t('Book Your Spiritual Umrah Journey')}}</h2>
									<h3  class="text-white mb-md-5"><small class="text-white">{{$t("headerText")}}</small></h3>
										<b-button :disabled="isLoading"  @click="bookNow" class="book-btn mt-2" >
											<span v-if="!isLoading">{{$t('Start Booking Now')}}</span>
											<b-spinner v-else small></b-spinner>
											<span v-if="isLoading">{{$t('Loading')}}...</span>
											
											
										</b-button>
								</div>
							</div><!--/.col-->
							<div class="col-sm-0">
								<div class="single-about-us">

								</div><!--/.single-about-us-->
							</div><!--/.col-->
						</div><!--/.row-->
					</div><!--/.about-us-content-->
				</div><!--/.container-->

			</section><!--/.about-us-->
		
		</div>
		<!--about-us end -->

		

		<!--service start-->
		<section id="service" class="service">
			<div class="container">

				<div class="service-counter text-start">

					<div class="col-md-4 col-sm-4">
						<div class="single-service-box">
							<div class="service-img pl-2">
								<i class="fas fa-kaaba" style="color: var(--primary-color); font-size: 45px;"></i>
								<!-- <img src="../assets/images/home/Layer_1.png" alt="service-icon" /> -->
							</div>
							<div class="service-content">
								<h2>
									<a href="#">
										Umrah experience
									</a>
								</h2>
								<p>Excellent rates and services delivering a seamless booking and Umrah experience</p>
								<p style="color: #E9B64A; margin-top: 10px;">Check out our services</p>

							</div>
						</div>
					</div>

					<div class="col-md-4 col-sm-4">
						<div class="single-service-box">
							<div class="service-img">
								<i class="fas fa-user-shield" style="color: var(--primary-color); font-size: 45px;"></i>
								
								<!-- <img src="../assets/images/home/Layer_1 (1).png" alt="service-icon" /> -->
							</div>
							<div class="service-content">
								<h2>
									<a href="#">
										Trusted brand
									</a>
								</h2>
								<p>Offers the utmost security and trust whilst booking your Umrah package.</p>
								<p style="color: #E9B64A; margin-top: 10px;">Login to bookings</p>

							</div><!--/.service-content-->
						</div><!--/.single-service-box-->
					</div><!--/.col-->

					<div class="col-md-4 col-sm-4">
						<div class="single-service-box">
							<div class="statistics-img">
								<i class="fas fa-mosque" style="color: var(--primary-color); font-size: 45px;"></i>
								
								<!-- <img src="../assets/images/home/Layer_1 (2).png" alt="service-icon" /> -->
							</div><!--/.service-img-->
							<div class="service-content">

								<h2>
									<a href="#">
										Tailor Umrah Trip
									</a>
								</h2>
								<p>Search thousands of flights, hotels & packages. Tailor make your Umrah trip all
									in one place</p>
								<p style="color: #E9B64A; margin-top: 10px;">Get it here for free</p>
							</div><!--/.service-content-->
						</div><!--/.single-service-box-->
					</div><!--/.col-->

				</div><!--/.statistics-counter-->
			</div><!--/.container-->

		</section>
		<!--/.service-->
		<!--service end-->

		<!--galley start-->



		<!--discount-offer start-->




		<!--blog start-->
		<section id="blog" class="blog">
			<div class="container">
				<div class="blog-details">
					<div class="gallary-header text-center">
						<h2>
							latest news & Tips
						</h2>
						<p>
							various post and latest news from authentic sources
						</p>
					</div><!--/.gallery-header-->
					<div class="blog-content">
						<div class="row">

							<div class="col-sm-4 col-md-4">
								<div class="thumbnail">
									<h2 class="text-dark">trending news <span class="text-dark">15 november 2017</span></h2>
									<div class="thumbnail-img">
										<img src="../assets/images/home/1215658647 1.png" alt="blog-img">
										<div class="thumbnail-img-overlay"></div><!--/.thumbnail-img-overlay-->

									</div><!--/.thumbnail-img-->

									<div class="caption">
										<div class="blog-txt">
											<a href="#Evisa" aria-controls="Evisa" role="tab" data-toggle="tab">
												<i class="fa fa-user"></i>
												Adam Hirachani
											</a>
											<h3>
												<a href="#">
													When does the Umrah season start?
												</a>
											</h3>
										</div><!--/.blog-txt-->
									</div><!--/.caption-->
								</div><!--/.thumbnail-->

							</div><!--/.col-->

							<div class="col-sm-4 col-md-4">
								<div class="thumbnail">
									<h2 class="text-dark">trending news <span class="text-dark">15 november 2017</span></h2>
									<div class="thumbnail-img">
										<img src="../assets/images/home/Tour-Hiker-7-Best-Places-to-Visit-in-Mecca-Masjid-al-Haram-scaled 1.png"
											alt="blog-img">
										<div class="thumbnail-img-overlay"></div><!--/.thumbnail-img-overlay-->

									</div><!--/.thumbnail-img-->
									<div class="caption">
										<div class="blog-txt">
											<a href="#Evisa" aria-controls="Evisa" role="tab" data-toggle="tab">
												<i class="fa fa-user"></i>
												Adam Hirachani
											</a>
											<h3>
												<a href="#">
													Rules of Ihram: Hajj and Umrah you must follow some basic </a>
											</h3>

										</div><!--/.blog-txt-->
									</div><!--/.caption-->
								</div><!--/.thumbnail-->

							</div><!--/.col-->

							<div class="col-sm-4 col-md-4">
								<div class="thumbnail">
									<h2 class="text-dark">trending news <span class="text-dark">15 november 2017</span></h2>
									<div class="thumbnail-img">
										<img src="../assets/images/home/0_Hajj_Pilgrimage_2018_b072dc3594 1.png"
											alt="blog-img">
										<div class="thumbnail-img-overlay"></div><!--/.thumbnail-img-overlay-->

									</div><!--/.thumbnail-img-->
									<div class="caption">
										<div class="blog-txt">
											<a href="#Evisa" aria-controls="Evisa" role="tab" data-toggle="tab">
												<i class="fa fa-user"></i>
												Adam Hirachani
											</a>
											<h3><a href="#">Planning for Umrah? Read this handy Article.</a></h3>

										</div><!--/.blog-txt-->
									</div><!--/.caption-->
								</div><!--/.thumbnail-->

							</div><!--/.col-->

						</div><!--/.row-->
					</div><!--/.blog-content-->
				</div><!--/.blog-details-->
			</div><!--/.container-->

		</section><!--/.blog-->
		<!--blog end-->


		<!--subscribe start-->
		<section id="subs" class="subscribe">
			<div class="container">
				<div class="subscribe-title text-center">
					<h2>
						Join our Subscribers List to Get Regular Update
					</h2>
					<p>
						Subscribe Now. We will send you Best offer for your Trip
					</p>
				</div>
				<form>
					<div class="row">
						<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
							<div class="custom-input-group">
								<input type="email" class="form-control" placeholder="Enter your Email Here">
								<button class="appsLand-btn subscribe-btn">Subscribe</button>
								<div class="clearfix"></div>
								<i class="fa fa-envelope"></i>
							</div>

						</div>
					</div>
				</form>
			</div>

		</section>
		<!--subscribe end-->

	</div>
</template>

<script>
import Swal from 'sweetalert2'
import {setCookie,getCookie,checkCookie,removeCookie}  from "../helpers/CookieHelpers.js"
import { mapActions, mapState } from "vuex";
export default {
	name: "Home",
	components: {
	},
	data() {
		return {
			isLoading:false
		};
	},
	computed: {
	},
	methods: {
		
		...mapActions("cart", ["checkStorage"]),
		...mapActions("booking",["saveBooking"]),
		bookNow(){
				var self=this;
				this.isLoading=true;
				var _id=checkCookie("currentBooking")?getCookie("currentBooking"):0;
			 this.saveBooking({ id:_id }).then(function(booking){
				
				
				if(booking && booking.id)
				{
					debugger;
					if((_id+"")!=(booking.id+""))
					{
						localStorage.removeItem("CartItems");
						// this.checkStorage();
					}

					setCookie("currentBooking",booking.id);					
					self.$router.push("booking/visa");
				
				}
				self.isLoading=false;
			 });
		}
	},
	mounted() {
	},
	created() {
		if(checkCookie("currentBooking"))
		{
			var currentBooking = getCookie("currentBooking");
			Swal.fire({
				title: 'Your booking is in process.Do you want to continue your journey?',
				showCancelButton: false,
				showDenyButton:true,
				confirmButtonText: 'Continue to booking',
				denyButtonText: `Discard`,
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					this.bookNow();
				} else if (result.isDenied) {
					removeCookie("currentBooking");
				}
			});
		}
	},
};
</script>

<style scoped></style>
