//Booking API URLS
export const POST_GET_BOOKING = `/booking`;

//Flight API URLS
export const GET_FLIGHT = `/flight`;


export const POST_ACCOUNT_LOGIN = `/auth/login`;
export const POST_ACCOUNT_FORGOTPASSWORD = `/auth/forgotPassword`;
export const POST_ACCOUNT_RESETPASSWORD = `/auth/resetPassword`;
export const POST_ACCOUNT_SIGNUP = `/auth/signup`;
export const FETCH_CURRENT_USER = `/auth/me`;


export const POST_ADMIN_LOGIN = `/admin/auth/login`;


//Flight API URLS
export const GET_TRANSPORTS = `/transport`;
export const GET_ZIYARATS = `/ziyarat`;

export const FETCH_POST_USERS_API = '/user';


//ROLES API URLS
export const FETCH_ROLES = `/roles`;
export const FETCH_ROLES_PERMISSIONS = `/roles/permissions`;