
<template>

    <div class="card-body">
        <div v-if="itenaries && itenaries.length>0">
            <label class="typo__label">Select Option</label>
            <v-select class="select2 search-input" v-model="selectedItenarary" :reduce="(option) => option.value"
				:options="itenaries" placeholder="Search by Option" :appendToBody="true" label="name">
				<template #selected-option="option">
					<div>
                        {{ option.name }}
					</div>
				</template>
				<template v-slot:option="option">
                    {{ option.name }}
				</template>
			</v-select>
        </div>
        
        <div class="mt-2">
            <label key="currency-input" class="typo__label">Currency</label>
            <select v-model="selectedCurrency" style="height: auto;" class="form-control" id="currency-input" >
                <option disabled> Select Currency</option>
                <option v-for="currency in currencies"  :value="currency">{{currency.toUpperCase()}}</option>
            </select>
        </div>
        
        <div class="mt-2">
            <label key="price-input" class="typo__label">Price</label>
            <input id="price-input" v-model="price" type="number" class="form-control"/>
        </div>
        <div class="mt-2">
            <label key="discount-input" class="typo__label">Discount</label>
            <input id="discount-input" v-model="discount" type="number" class="form-control"/>
        </div>
        <p class="text-danger" v-if="showDiscountError">Incorrect discount value</p>
        <div class="mt-2" v-if="price && selectedCurrency && totalprice && totalprice>0">
            <label key="total-price-input" class="typo__label">Total Price</label>
            <p><b>{{selectedCurrency.toUpperCase()}}</b> {{ totalprice.toFixed(2) }}</p>
        </div>
        <div style="display: flex;" class="mt-4">
            <div style="flex: 1; margin-right: 10px;">
                <button  @click="save()" :disabled="!(selectedItenarary && selectedCurrency && price && totalprice>0  )" class="btn btn-secondary mt-2" style="padding: 2px; font-size: x-small; width: 100%;">
                    Save</button>
            </div>
            <div style="flex: 1; margin-left: 10px;">
                <button @click="()=>{ $emit('closeTransportOption',false); }"   class="btn btn-danger mt-2" style="padding: 2px; font-size: x-small; width: 100%;">
                    Cancel</button>
            </div>
        </div>
        
    </div>



      
</template>
<script>

import helpers from "./../../../helpers/CookieHelpers";
import ConfigHelper from "./../../../helpers/ConfigHelper";
import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import Vue from "vue";
export default {
    name: "transportOptionCreationBox",
    props:{
        transportObject:Object,
        transportOption:Object
    },
    data() {
        return {
            itenaries:[
                { value : "MedAirport-Med".toLowerCase(), name:"Al-Madinah al-Munawwarah Airport to Hotel"},
                { value : "Med-MedAirport".toLowerCase(), name:"Al-Madinah al-Munawwarah Hotel to Airport"},
                { value : "Mak-JedAirport".toLowerCase(), name:"Makkah al-Mukarramah Hotel to Jeddah Airport"},
                { value : "JedAirport-Mak".toLowerCase(), name:"Jeddah Airport to Makkah al-Mukarramah Hotel"},
                { value : "Mak-Med".toLowerCase(), name:"Makkah al-Mukarramah Hotel to Al-Madinah al-Munawwarah Hotel"},
                { value : "Med-Mak".toLowerCase(), name:"Al-Madinah al-Munawwarah Hotel to Makkah al-Mukarramah Hotel"}                                
            ],
            currencies:[],
            selectedItenarary:null,
            selectedCurrency:null,
            price:null,
            discount:null
        };
    },
    computed: {
        totalprice()
        {
            if(this.price && this.price>0)
            {
                return this.price - (this.discount??0);
            }
            return null;
        },
        showDiscountError(){
            
            if(this.discount && this.price<this.discount)
            {
                return true;
            }

            return false;
        }
    },
    methods: {
        
        ...mapActions("transport", ["saveTransport","getTransports","saveTransportOption"]),
    ...mapActions("ziyarat", ["saveZiyaratOffer"]),
    save()
    {
        
        if(this.selectedItenarary && this.selectedCurrency && this.price && this.totalprice>0)
        {
            this.saveTransportOption({ transportId:this.transportObject.id, transportOption:{
                id:this.transportOption?.id,
                itenarary:this.selectedItenarary,
                Currency:this.selectedCurrency,
                Price:this.price,
                Discount:this.discount
            }}).then(()=>{
                this.$emit('closeTransportOption',true);
            });
        }
    }

    },
    components: {
    },
    created() {
        var self=this;
       
        ConfigHelper.getCurrencies().then(function(currencies){
            console.log(currencies,"currencies");
            self.currencies=currencies;
        });

        if(this.transportOption)
        {
            this.selectedItenarary=this.transportOption?.itenarary;
            this.selectedCurrency=this.transportOption?.currency?.toLowerCase();
            this.price=this.transportOption?.price;
            this.discount=this.transportOption?.discount;
        
        }
    },
    mounted() {
    },
    watch: {},
};
</script>