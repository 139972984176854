import store from "./index";

import Swal from 'sweetalert2';
const state = {
	cartItems:[]
};

const getters = {
};

const actions = {
	checkStorage(){
		if(localStorage.getItem("CartItems"))
		{
			if(localStorage.getItem("CartItem_Date"))
			{
				var date = new Date(localStorage.getItem("CartItem_Date"));
				date.setHours(date.getHours() + 1);
				if(date<new Date())
				{
					localStorage.removeItem("CartItems");
					return;
				}
			}


			var json = JSON.parse(localStorage.getItem("CartItems"));
			state.cartItems=json;
		}
	},
	addPackageToCart: async function ({ commit,dispatch },_package) {
		if (state.cartItems.find(x => x.type == "flight" || x.type == "stay")) {
			Swal.fire({
				title: 'Confirmation',
				html: `You have to remove flight and stay items from cart.`,
				showDenyButton: true,
				confirmButtonText: 'Remove',
				confirmButtonAriaLabel: 'Remove',
				denyButtonText: 'Cancel',
				cancelButtonAriaLabel: 'Cancel'
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					
					var _index = state.cartItems.indexOf(state.cartItems.find(x => x.type == "flight"));
					if (_index > -1) {
						state.cartItems.splice(index, 1);
					}
					for(var m=0; state.cartItems.indexOf(state.cartItems.find(x=>x.type=="stay"))!=-1;m++ )
					{
						var _indexOf=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="stay"));
						if(_indexOf!=-1)
							state.cartItems.splice(_indexOf,1);
					}
					state.cartItems.push({ type:"package", data:_package});
				} else if (result.isDenied) {
				
				}
			});
			return;
		}



		var index=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="package"));
		if(index>-1)
		{
			state.cartItems.splice(index,1);
		}
		if(_package)
		{
			state.cartItems.push({ type:"package", data:_package});
		}
	},
	addTransportToCart: async function ({ commit,dispatch },{bookingId, transportId}) {
		var index=state.cartItems.indexOf(state.cartItems.find(x=>x.type.toLowerCase()=="transport"));
		if(index>-1)
		{
			state.cartItems.splice(index,1);
		}
		
		if(transportId)
		{
			var transportoffer = await dispatch('booking/getBookingTransportById', { bookingId, transportId}, { root: true })
				.then(transport => {
					if(transport)
					{

						state.cartItems.push({ type:"transport", data:transport});
						return transport;
					}
				})
				.catch(error => {
					
				});
			return transportoffer;
		}
	},
	addStayToCart: async function ({ commit,dispatch },{bookingId, stayId, city}) {
		
		var index=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="stay" && x.city==city));
		if(index>-1)
		{
			state.cartItems.splice(index,1);
		}	
		if(stayId)
		{
			var stayoffer = await dispatch('booking/getBookingStayById', { bookingId, stayId}, { root: true })
				.then(stay => {
					if(stay)
					{

						state.cartItems.push({ type:"stay",city, data:stay});
						return stay;
					}
				})
				.catch(error => {
					
				});
			return stayoffer;
		}
	},
	addFlightToCart: async function ({ commit,dispatch },flight) {
		var index=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="package"));
		if(index>-1)
		{
			state.cartItems.splice(index,1);
		}
		
		index=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="flight"));
		if(index>-1)
		{
			state.cartItems.splice(index,1);
		}
		if(flight)
		{
			var temp=state.cartItems;
			state.cartItems=[];
			state.cartItems.push({ type:"flight", data:flight});
			temp.forEach(x=>{
				state.cartItems.push(x);
			});
			var lse=flight.data.itineraries[0].segments;
			if(lse && lse.length>0)
			{
				var fdate=new Date(lse[lse.length-1].arrival.at).toDateString();	
				var sdates=state.cartItems.filter(x=>x.type=="stay").map(x=>new Date(x.data.offers[0].checkInDate));
				var sdate = sdates.length>0?new Date(Math.min.apply(null,sdates)):null;
				if(sdate && sdate.toDateString()!=fdate)
				{
					for(var m=0; state.cartItems.indexOf(state.cartItems.find(x=>x.type=="stay"))!=-1;m++ )
					{
						var _indexOf=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="stay"));
						if(_indexOf!=-1)
							state.cartItems.splice(_indexOf,1);
					}
					
				}
			}
		
		}
		
	},
	addZiyaratToCart: async function ({ commit,dispatch },{bookingId,ziyaratId, ziyarayOfferId, on}) {
		var index=state.cartItems.indexOf(state.cartItems.find(x=>x.type=="ziyarat" && x.data.id==ziyaratId));
		if(index>-1)
		{
			state.cartItems.splice(index,1);
		}	
		
		if(ziyaratId && ziyarayOfferId)
		{
			var ziyaratoffer = await dispatch('booking/getBookingZiyaratById', { bookingId, ziyaratId}, { root: true })
				.then(ziyarat => {
					
					if(ziyarat && ziyarat.offers)
					{
						ziyarat.offers=ziyarat.offers.find(x=>x.id==ziyarayOfferId);
					}
					if(ziyarat && ziyarat.offers)
					{
						ziyarat.on=on;
						state.cartItems.push({ type:"ziyarat", data:ziyarat});
						return ziyarat;
					}
				})
				.catch(error => {
					
				});
			return ziyaratoffer;
		}
	}

};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
  };