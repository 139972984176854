import cookieHelpers from "./CookieHelpers";
import Vue from "vue";
// import { cookieHelpers.setCookie, cookieHelpers.getCookie } from "../helpers/CookieHelpers";

async function makeAPICall(
	url,
	method,
	body,
	isLoginApi,
	contentType = "application/json"
) {
	// if (store.state.appState.amIOnline === false) return;
	// console.log(url, method, body, isLoginApi);
	var requestOptions = {
		method: method,
		headers: {
			AuthToken: "System123"		
		},
	};
	// alert(ua.device.type);
	if (contentType) {
		requestOptions.headers["Content-Type"] = contentType;
	}

	// if (isAuthRequired) {
	const token = cookieHelpers.getCookie("token");
	if (token) {
		requestOptions.headers.Authorization = `Bearer ${token}`;
	}
	// }


	if (body !== null) {
		requestOptions.body = body;
	}

	const data = await fetch(url, requestOptions)
		// .then(handleErrors)
		.then(async (response) => {
			if (!response) {
				return Promise.reject({
					status: 500,
					ok: false,
					message:
						"Some unexpected error occurred. Please check your internet connection and try again. If the problem persists please contact admin.",
				});
			}
			// console.log(response);
			if (!response.ok) {
				if (response.status == 500) {
					return Promise.reject({
						status: response.status,
						ok: response.ok,
						message:
							"Some unexpected error occurred. Please try again or contact admin.",
					});
				}
				if (response.status == 403 ) {
					var error= await response.clone().json();
					if (error.error && error.error.errorCode == 133)
						return Promise.reject({
							status: error.error.errorCode,
							ok: response.ok,
							message: error.error.message,
						});
				}
				var isTokenExpired = response.headers.get("tokenexpired");
				var isTokenInvalid =
					response.headers.get("www-authenticate") &&
					response.headers.get("www-authenticate").includes("invalid_token");
				// console.log(response.headers.get("date"));
				var timeSinceLastRegen =
					new Date().getTime() -
					parseInt(JSON.parse(localStorage.getItem("last_token_time")));
				console.log(
					"token expired: ",
					isTokenExpired,
					isTokenInvalid,
					timeSinceLastRegen
				);
				return response;
			} else return response;
		})
		.catch((error) => {
			console.log(error);
			if (error.status && error.status == 500) {
				Vue.toasted.error(error.message, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					singleton: true,
					action: {
						text: "DISMISS",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						},
					},
				});
				return Promise.reject(error);
			}
			if (error.status && error.status == 133) {
               console.log(Vue.prototype);
			   Vue.prototype.$Modal.msgBoxOk(
				`${error.message}`,
				{
					title: "Access Denied",
					size: "md",
					buttonSize: "md",
					okTitle: "Ok",
					okVariant: "danger",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
					bodyClass: "confirm-modal-body",
				}
			);
				
				return Promise.reject(error);
			}
			return Promise.reject({
				status: 500,
				ok: false,
				message:
					"Some unexpected error occurred. Please check your internet connection and try again. If the problem persists please contact admin.",
			});
		});
	// console.log("response from the API", data);
	return data;
}



export default {
	makeAPICall,
	
};
