import { FETCH_ROLES, FETCH_ROLES_PERMISSIONS } from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

import ConfigHelper from "../helpers/ConfigHelper";
export const rolesService = {
  fetchRoles,
  deleteRole,
  getRolePermissions,
  addRole,
  editRole,
};

async function fetchRoles() {
    var url=await ConfigHelper.getAPIUrl();
  const res = await ApiHelpers.makeAPICall(
    url + FETCH_ROLES,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}

async function fetchUserRoles(userid) { 
    var url=await ConfigHelper.getAPIUrl();
    const res = await ApiHelpers.makeAPICall(
        url + FETCH_USER_ROLES + `/${userid}`,
      "GET",
      null,
      false
    ).then(handleResponse);
    return res;
  }

async function deleteRole(id) {
    var url=await ConfigHelper.getAPIUrl();
  const res = await ApiHelpers.makeAPICall(
    url + FETCH_ROLES + `/${id}`,
    "DELETE",
    null,
    false
  ).then(handleResponse);
  return res;
}

async function getRolePermissions() {
    var url=await ConfigHelper.getAPIUrl();
    const res = await ApiHelpers.makeAPICall(
    url + FETCH_ROLES_PERMISSIONS,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}

async function addRole(data) {
    var url=await ConfigHelper.getAPIUrl();
  try {
    let body = await ApiHelpers.makeAPICall(
      url + FETCH_ROLES,
      "POST",
      JSON.stringify(data),
      false
    ).then(handleResponse);
    return body;
  } finally {
    }
}

async function editRole(data) {
    var url=await ConfigHelper.getAPIUrl();
  try {
    let body = await ApiHelpers.makeAPICall(
     url + FETCH_ROLES,
      "PUT",
      JSON.stringify(data),
      false
    ).then(handleResponse);
    return body;
  } finally {
  }
}

function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // 	// if (response.status == 401) {
    // 	console.log("status", response.status);
    // 	store.dispatch("user/regenerateUserToken", {
    // 		token: helpers.getCookie("token"),
    // 	});
    // return;
    // }
    throw new Error(response.status);
  } else {
    return response.json();
  }
}