import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";
import ConfigHelper from "../helpers/ConfigHelper";

import {
  POST_ACCOUNT_LOGIN, FETCH_CURRENT_USER, POST_ACCOUNT_SIGNUP, POST_ACCOUNT_FORGOTPASSWORD, POST_ACCOUNT_RESETPASSWORD, POST_ADMIN_LOGIN
} from "../constants/urls";
export const accountService = {
  login, getCurrentUser, logout, register, forgotPassword, resetPassword, adminlogin
};

async function login(username, password) {
  var url=await ConfigHelper.getAPIUrl();
  
  const response = await APIHelpers.makeAPICall(
    
    url + POST_ACCOUNT_LOGIN,
    "POST",
    JSON.stringify({ username, password }),
    true
  ).then(handleResponse);
  
  if (response?.payload?.token) {
    helpers.setCookie(
      "token",
      response?.payload?.token,
      new Date(new Date().setDate(new Date().getDate() + 30)).toUTCString()
    );
    localStorage.setItem(
      "last_token_time",
      JSON.stringify(new Date().getTime())
    );

    return response;
  }
}

async function adminlogin(username, password) {
  var url=await ConfigHelper.getAPIUrl();
  
  const response = await APIHelpers.makeAPICall(
    
    url + POST_ADMIN_LOGIN,
    "POST",
    JSON.stringify({ username, password }),
    true
  ).then(handleResponse);
  
  if (response?.payload?.token) {
    helpers.setCookie(
      "token",
      response?.payload?.token,
      new Date(new Date().setDate(new Date().getDate() + 30)).toUTCString()
    );
    localStorage.setItem(
      "last_token_time",
      JSON.stringify(new Date().getTime())
    );

    return response;
  }
}

async function forgotPassword(email,redirectUrl) {
  var url=await ConfigHelper.getAPIUrl();
  
  const response = await APIHelpers.makeAPICall(
    
    url + POST_ACCOUNT_FORGOTPASSWORD,
    "POST",
    JSON.stringify({ email, redirectUrl }),
    true
  ).then(handleResponse);
  
  if (response?.payload?.token) {
    return response;
  }
}

async function resetPassword(code,password) {
  var url=await ConfigHelper.getAPIUrl();
  
  const response = await APIHelpers.makeAPICall(
    
    url + POST_ACCOUNT_RESETPASSWORD,
    "POST",
    JSON.stringify({ password, code }),
    true
  ).then(handleResponse);
  
  if (response?.payload?.token) {
    return response;
  }
}

async function register(user) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
    url + POST_ACCOUNT_SIGNUP,
    "POST",
    JSON.stringify(user),
    false
  ).then(handleResponse);
  return response;
}
async function getCurrentUser() {
  var url=await ConfigHelper.getAPIUrl();
 
  const response = await ApiHelpers.makeAPICall(
    url + FETCH_CURRENT_USER,
    "GET",
    null,
    false
  ).then(handleResponse);
  return response;
}

async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}

function logout() {
  localStorage.removeItem("user");
  helpers.removeCookie("token");
}

