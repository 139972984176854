<template>
    <div class="mt-3">
        <div style="display: flex;">

            <h5 class="card-title mr-3" style="flex: 1;">Transports</h5>
            <button v-if="!isAdding && havePermit(['ADD_OR_UPDATE_TRANSPORT'])" @click="() => {
                isAdding = true; editId = null;
                setTimeout(function(){
                    document.getElementById('new-option').scrollToView();
                },3000);
            }" class="btn btn-primary mr-4"
                style="background: transparent; padding: 0px; border: 4px solid var(--primary-color); color: var(--primary-color); border-radius: 20px; width: 40px; height: 40px; ">
                <i class="fas fa-plus"></i>
            </button>

        </div>
        <h5 v-if="!options"><center>Loading.....</center></h5>
        
        <h5 v-if="!isAdding && options && options.length==0"><center>No Data Found</center></h5>
        <div class="row"  >

            <div v-for="option in options?options:[]" class="col-xxl-3 col-md-4 mb-4">
                <div class="card info-card sales-card" style="background: whitesmoke;">


                    <div class="card-body" v-if="editId != option.id">
                       
                        <div style="display: flex;">
                            <h5 style="flex: 1; margin: 0px; padding: 0px;" class="card-title" >
                                <b v-if="option.itenarary.toLowerCase()=='MedAirport-Med'.toLowerCase()">Al-Madinah al-Munawwarah Airport to Hotel</b>
                                <b v-else-if="option.itenarary.toLowerCase()=='Med-MedAirport'.toLowerCase()">Al-Madinah al-Munawwarah Hotel to Airport</b>
                                <b v-else-if="option.itenarary.toLowerCase()=='Mak-JedAirport'.toLowerCase()">Makkah al-Mukarramah Hotel to Jeddah Airport</b>
                                <b v-else-if="option.itenarary.toLowerCase()=='JedAirport-Mak'.toLowerCase()">Jeddah Airport to Makkah al-Mukarramah Hotel</b>
                                <b v-else-if="option.itenarary.toLowerCase()=='Mak-Med'.toLowerCase()">Makkah al-Mukarramah Hotel to Al-Madinah al-Munawwarah Hotel</b>
                                <b v-else-if="option.itenarary.toLowerCase()=='Med-Mak'.toLowerCase()">Al-Madinah al-Munawwarah Hotel to Makkah al-Mukarramah Hotel</b>
                            </h5>
                            <a v-if="havePermit(['ADD_OR_UPDATE_TRANSPORT'])" class="mt-4 mr-3" @click="editOption(option.id)" style="cursor: pointer;"><i
                                    class="fas fa-pen text-primary"></i></a>
                            <a v-if="havePermit(['DELETE_TRANSPORT'])" class="mt-4" @click="deleteTransportOptionClick(option.id)" style="cursor: pointer;"><i class="fas fa-trash text-danger"></i></a>
                        </div>
                        <div class="d-flex align-items-center">
                            
                            <div class="ps-2 ml-3 feature-text">
                               
                                <div>
                                    <h6 style="padding-top: 5px; font-size: medium; text-align: left; flex:1" class="mt-2 mr-3 pr-3">
                                        Price : <span>{{ option.currency.toUpperCase() }} {{
                                            option.price.toFixed(2) }}</span>
                                    </h6>

                                    <h6 v-if="option.discount" style="padding-top: 5px; font-size: medium;  text-align: left; flex:1"
                                        class="mt-2 mr-3 pr-3">
                                        Discount : <span>{{ option.currency.toUpperCase() }} {{
                                            option.discount.toFixed(2) }}</span>
                                    </h6>
                                    <h4 style=" line-height: 15px; padding-top: 5px;" class="mt-2 mr-3 pr-3 price">
                                        {{ option.currency.toUpperCase() }} {{ (option.price -
                                            (option.discount ? option.discount : 0)).toFixed(2) }}<br />
                                        <small style="font-size: x-small;">Total Price</small>
                                    </h4>

                                    <!-- <button  class="mt-2 select-btn btn btn-primary">
							Select This Transport
						</button> -->
                                </div>
                                <!-- <div v-if="transport.properties" style="display: flex; "
                        v-for="feature in readFeatures(transport.properties)" class="mb-3">
                        <i style="color: var(--primary-color);" class="fas fa-star mr-3"></i>
                        <p>
                          {{ feature }}
                        </p>
                      </div> -->

                            </div>
                        </div>
                    </div>
                   
                    <TransportOptionCreationBox
                    v-else
                    :transportOption="option"
                    @closeTransportOption="(isRefresh) => { if (isRefresh) { getOptions(); } isAdding = false; editId=null; }"
                    :transportObject="selectedTransport"></transportOptionCreationBox>
                   
                </div>
            </div>
            <div class="col-xxl-3 col-md-4 mb-4" id="new-option" v-if="isAdding && havePermit(['ADD_OR_UPDATE_TRANSPORT'])" >
                <div class="card info-card sales-card" style="background: whitesmoke;">
                    <TransportOptionCreationBox
                        @closeTransportOption="(isRefresh) => { if (isRefresh) { getOptions(); } isAdding = false; }"
                        :transportObject="selectedTransport"></transportOptionCreationBox>
                </div>
            </div>


        </div>

    </div>
</template>
<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import LightBox from 'vue-image-lightbox';
import Swal from 'sweetalert2'
import Vue from "vue";
import TransportOptionCreationBox from "./transportOptionCreationBox.vue";
import {havePermit} from "./../../../helpers/FormatHelpers";
export default {
    name: "transportOptionsList",
    data() {
        return {
            transportOptions: null,
            isAdding: false,
            editId: null,
            options:null,
            editId:null
        };
    },
    props: {
        selectedTransport: Object
    },
    computed: {
        
    },
    methods: {    
        havePermit,    
        ...mapActions("transport", ["getTransportWithOptions","deleteTransportOption"]),        
        editOption(id) {
            this.isAdding = false;
            this.editId = id;
        },
        getOptions(){
            if(this.selectedTransport)
            {
                this.options=null;
                var self=this;
                this.getTransportWithOptions(this.selectedTransport.id).then((transport)=>{
                   if(transport)
                   {
                        self.options=transport.options??[];
                   }
                   else{
                    self.options=[];
                   }
                });
            }
        }  ,
        deleteTransportOptionClick(transportOptionId) {
            var self = this;
            if (transportOptionId) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.deleteTransportOption(transportOptionId).then(() => {
                            self.getOptions();
                        });
                    }
                });

            }
        },
     
    },
    components: {
        TransportOptionCreationBox
    },
    created() {
       
        this.getOptions();
    },
    mounted() {
    },
    watch: {
        selectedTransport:function(){
            this.getOptions();
        }
    },
};
</script>
<style scoped></style>