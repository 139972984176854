<template>
    <div>
      
          <div class="pagetitle">
            <h1>Dashboard</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active">Dashboard</li>
              </ol>
            </nav>
          </div><!-- End Page Title -->
      
          <section class="section dashboard">
            <div class="row">
      
              <!-- Left side columns -->
              <div class="col-lg-8">
                <div class="row">
      
                  <!-- Sales Card -->
                  <div class="col-xxl-4 col-md-6">
                    <div class="card info-card sales-card">
      
                      <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li class="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
      
                          <li><a class="dropdown-item" href="#">Today</a></li>
                          <li><a class="dropdown-item" href="#">This Month</a></li>
                          <li><a class="dropdown-item" href="#">This Year</a></li>
                        </ul>
                      </div>
      
                      <div class="card-body">
                        <h5 class="card-title">Sales <span>| Today</span></h5>
      
                        <div class="d-flex align-items-center">
                          <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i class="bi bi-cart"></i>
                          </div>
                          <div class="ps-3">
                            <h6>145</h6>
                            <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span>
      
                          </div>
                        </div>
                      </div>
      
                    </div>
                  </div><!-- End Sales Card -->
      
                  <!-- Revenue Card -->
                  <div class="col-xxl-4 col-md-6">
                    <div class="card info-card revenue-card">
      
                      <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li class="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
      
                          <li><a class="dropdown-item" href="#">Today</a></li>
                          <li><a class="dropdown-item" href="#">This Month</a></li>
                          <li><a class="dropdown-item" href="#">This Year</a></li>
                        </ul>
                      </div>
      
                      <div class="card-body">
                        <h5 class="card-title">Revenue <span>| This Month</span></h5>
      
                        <div class="d-flex align-items-center">
                          <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i class="bi bi-currency-dollar"></i>
                          </div>
                          <div class="ps-3">
                            <h6>$3,264</h6>
                            <span class="text-success small pt-1 fw-bold">8%</span> <span class="text-muted small pt-2 ps-1">increase</span>
      
                          </div>
                        </div>
                      </div>
      
                    </div>
                  </div><!-- End Revenue Card -->
      
                  <!-- Customers Card -->
                  <div class="col-xxl-4 col-xl-12">
      
                    <div class="card info-card customers-card">
      
                      <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li class="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
      
                          <li><a class="dropdown-item" href="#">Today</a></li>
                          <li><a class="dropdown-item" href="#">This Month</a></li>
                          <li><a class="dropdown-item" href="#">This Year</a></li>
                        </ul>
                      </div>
      
                      <div class="card-body">
                        <h5 class="card-title">Customers <span>| This Year</span></h5>
      
                        <div class="d-flex align-items-center">
                          <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i class="bi bi-people"></i>
                          </div>
                          <div class="ps-3">
                            <h6>1244</h6>
                            <span class="text-danger small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">decrease</span>
      
                          </div>
                        </div>
      
                      </div>
                    </div>
      
                  </div><!-- End Customers Card -->
      
                  <!-- Reports -->
                  <div class="col-12">
                    <div class="card">
      
                      <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li class="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
      
                          <li><a class="dropdown-item" href="#">Today</a></li>
                          <li><a class="dropdown-item" href="#">This Month</a></li>
                          <li><a class="dropdown-item" href="#">This Year</a></li>
                        </ul>
                      </div>
      
                      <div class="card-body">
                        <h5 class="card-title">Reports <span>/Today</span></h5>
      
                        <!-- Line Chart -->
                        <div id="reportsChart"></div>
                        <!-- End Line Chart -->
      
                      </div>
      
                    </div>
                  </div><!-- End Reports -->
      
                  <!-- Recent Sales -->
                  <div class="col-12">
                    <div class="card recent-sales overflow-auto">
      
                      <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li class="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
      
                          <li><a class="dropdown-item" href="#">Today</a></li>
                          <li><a class="dropdown-item" href="#">This Month</a></li>
                          <li><a class="dropdown-item" href="#">This Year</a></li>
                        </ul>
                      </div>
      
                      <div class="card-body">
                        <h5 class="card-title">Recent Sales <span>| Today</span></h5>
      
                        <table class="table table-borderless datatable">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Customer</th>
                              <th scope="col">Product</th>
                              <th scope="col">Price</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row"><a href="#">#2457</a></th>
                              <td>Brandon Jacob</td>
                              <td><a href="#" class="text-primary">At praesentium minu</a></td>
                              <td>$64</td>
                              <td><span class="badge bg-success">Approved</span></td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#">#2147</a></th>
                              <td>Bridie Kessler</td>
                              <td><a href="#" class="text-primary">Blanditiis dolor omnis similique</a></td>
                              <td>$47</td>
                              <td><span class="badge bg-warning">Pending</span></td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#">#2049</a></th>
                              <td>Ashleigh Langosh</td>
                              <td><a href="#" class="text-primary">At recusandae consectetur</a></td>
                              <td>$147</td>
                              <td><span class="badge bg-success">Approved</span></td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#">#2644</a></th>
                              <td>Angus Grady</td>
                              <td><a href="#" class="text-primar">Ut voluptatem id earum et</a></td>
                              <td>$67</td>
                              <td><span class="badge bg-danger">Rejected</span></td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#">#2644</a></th>
                              <td>Raheem Lehner</td>
                              <td><a href="#" class="text-primary">Sunt similique distinctio</a></td>
                              <td>$165</td>
                              <td><span class="badge bg-success">Approved</span></td>
                            </tr>
                          </tbody>
                        </table>
      
                      </div>
      
                    </div>
                  </div><!-- End Recent Sales -->
      
                  <!-- Top Selling -->
                  <div class="col-12">
                    <div class="card top-selling overflow-auto">
      
                      <div class="filter">
                        <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li class="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
      
                          <li><a class="dropdown-item" href="#">Today</a></li>
                          <li><a class="dropdown-item" href="#">This Month</a></li>
                          <li><a class="dropdown-item" href="#">This Year</a></li>
                        </ul>
                      </div>
      
                      <div class="card-body pb-0">
                        <h5 class="card-title">Top Selling <span>| Today</span></h5>
      
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col">Preview</th>
                              <th scope="col">Product</th>
                              <th scope="col">Price</th>
                              <th scope="col">Sold</th>
                              <th scope="col">Revenue</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-1.jpg" alt=""></a></th>
                              <td><a href="#" class="text-primary fw-bold">Ut inventore ipsa voluptas nulla</a></td>
                              <td>$64</td>
                              <td class="fw-bold">124</td>
                              <td>$5,828</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-2.jpg" alt=""></a></th>
                              <td><a href="#" class="text-primary fw-bold">Exercitationem similique doloremque</a></td>
                              <td>$46</td>
                              <td class="fw-bold">98</td>
                              <td>$4,508</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-3.jpg" alt=""></a></th>
                              <td><a href="#" class="text-primary fw-bold">Doloribus nisi exercitationem</a></td>
                              <td>$59</td>
                              <td class="fw-bold">74</td>
                              <td>$4,366</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-4.jpg" alt=""></a></th>
                              <td><a href="#" class="text-primary fw-bold">Officiis quaerat sint rerum error</a></td>
                              <td>$32</td>
                              <td class="fw-bold">63</td>
                              <td>$2,016</td>
                            </tr>
                            <tr>
                              <th scope="row"><a href="#"><img src="assets/img/product-5.jpg" alt=""></a></th>
                              <td><a href="#" class="text-primary fw-bold">Sit unde debitis delectus repellendus</a></td>
                              <td>$79</td>
                              <td class="fw-bold">41</td>
                              <td>$3,239</td>
                            </tr>
                          </tbody>
                        </table>
      
                      </div>
      
                    </div>
                  </div><!-- End Top Selling -->
      
                </div>
              </div><!-- End Left side columns -->
      
              <!-- Right side columns -->
              <div class="col-lg-4">
      
                <!-- Recent Activity -->
                <div class="card">
                  <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
      
                      <li><a class="dropdown-item" href="#">Today</a></li>
                      <li><a class="dropdown-item" href="#">This Month</a></li>
                      <li><a class="dropdown-item" href="#">This Year</a></li>
                    </ul>
                  </div>
      
                  <div class="card-body">
                    <h5 class="card-title">Recent Activity <span>| Today</span></h5>
      
                    <div class="activity">
      
                      <div class="activity-item d-flex">
                        <div class="activite-label">32 min</div>
                        <i class='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                        <div class="activity-content">
                          Quia quae rerum <a href="#" class="fw-bold text-dark">explicabo officiis</a> beatae
                        </div>
                      </div><!-- End activity item-->
      
                      <div class="activity-item d-flex">
                        <div class="activite-label">56 min</div>
                        <i class='bi bi-circle-fill activity-badge text-danger align-self-start'></i>
                        <div class="activity-content">
                          Voluptatem blanditiis blanditiis eveniet
                        </div>
                      </div><!-- End activity item-->
      
                      <div class="activity-item d-flex">
                        <div class="activite-label">2 hrs</div>
                        <i class='bi bi-circle-fill activity-badge text-primary align-self-start'></i>
                        <div class="activity-content">
                          Voluptates corrupti molestias voluptatem
                        </div>
                      </div><!-- End activity item-->
      
                      <div class="activity-item d-flex">
                        <div class="activite-label">1 day</div>
                        <i class='bi bi-circle-fill activity-badge text-info align-self-start'></i>
                        <div class="activity-content">
                          Tempore autem saepe <a href="#" class="fw-bold text-dark">occaecati voluptatem</a> tempore
                        </div>
                      </div><!-- End activity item-->
      
                      <div class="activity-item d-flex">
                        <div class="activite-label">2 days</div>
                        <i class='bi bi-circle-fill activity-badge text-warning align-self-start'></i>
                        <div class="activity-content">
                          Est sit eum reiciendis exercitationem
                        </div>
                      </div><!-- End activity item-->
      
                      <div class="activity-item d-flex">
                        <div class="activite-label">4 weeks</div>
                        <i class='bi bi-circle-fill activity-badge text-muted align-self-start'></i>
                        <div class="activity-content">
                          Dicta dolorem harum nulla eius. Ut quidem quidem sit quas
                        </div>
                      </div><!-- End activity item-->
      
                    </div>
      
                  </div>
                </div><!-- End Recent Activity -->
      
                <!-- Budget Report -->
                <div class="card">
                  <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
      
                      <li><a class="dropdown-item" href="#">Today</a></li>
                      <li><a class="dropdown-item" href="#">This Month</a></li>
                      <li><a class="dropdown-item" href="#">This Year</a></li>
                    </ul>
                  </div>
      
                  <div class="card-body pb-0">
                    <h5 class="card-title">Budget Report <span>| This Month</span></h5>
      
                    <div id="budgetChart" style="min-height: 400px;" class="echart"></div>
      
      
                  </div>
                </div><!-- End Budget Report -->
      
                <!-- Website Traffic -->
                <div class="card">
                  <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
      
                      <li><a class="dropdown-item" href="#">Today</a></li>
                      <li><a class="dropdown-item" href="#">This Month</a></li>
                      <li><a class="dropdown-item" href="#">This Year</a></li>
                    </ul>
                  </div>
      
                  <div class="card-body pb-0">
                    <h5 class="card-title">Website Traffic <span>| Today</span></h5>
      
                    <div id="trafficChart" style="min-height: 400px;" class="echart"></div>
      
      
                  </div>
                </div><!-- End Website Traffic -->
      
                <!-- News & Updates Traffic -->
                <div class="card">
                  <div class="filter">
                    <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li class="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>
      
                      <li><a class="dropdown-item" href="#">Today</a></li>
                      <li><a class="dropdown-item" href="#">This Month</a></li>
                      <li><a class="dropdown-item" href="#">This Year</a></li>
                    </ul>
                  </div>
      
                  <div class="card-body pb-0">
                    <h5 class="card-title">News &amp; Updates <span>| Today</span></h5>
      
                    <div class="news">
                      <div class="post-item clearfix">
                        <img src="assets/img/news-1.jpg" alt="">
                        <h4><a href="#">Nihil blanditiis at in nihil autem</a></h4>
                        <p>Sit recusandae non aspernatur laboriosam. Quia enim eligendi sed ut harum...</p>
                      </div>
      
                      <div class="post-item clearfix">
                        <img src="assets/img/news-2.jpg" alt="">
                        <h4><a href="#">Quidem autem et impedit</a></h4>
                        <p>Illo nemo neque maiores vitae officiis cum eum turos elan dries werona nande...</p>
                      </div>
      
                      <div class="post-item clearfix">
                        <img src="assets/img/news-3.jpg" alt="">
                        <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                        <p>Fugiat voluptas vero eaque accusantium eos. Consequuntur sed ipsam et totam...</p>
                      </div>
      
                      <div class="post-item clearfix">
                        <img src="assets/img/news-4.jpg" alt="">
                        <h4><a href="#">Laborum corporis quo dara net para</a></h4>
                        <p>Qui enim quia optio. Eligendi aut asperiores enim repellendusvel rerum cuder...</p>
                      </div>
      
                      <div class="post-item clearfix">
                        <img src="assets/img/news-5.jpg" alt="">
                        <h4><a href="#">Et dolores corrupti quae illo quod dolor</a></h4>
                        <p>Odit ut eveniet modi reiciendis. Atque cupiditate libero beatae dignissimos eius...</p>
                      </div>
      
                    </div><!-- End sidebar recent posts-->
      
                  </div>
                </div><!-- End News & Updates -->
      
              </div><!-- End Right side columns -->
      
            </div>
          </section>
      
       
      
      
    </div>
      
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import Vue from "vue";
export default {
	name: "AdminHome",
	data() {
		return {
		};
	},
	computed: {
		
		...mapState("account", ["user","loggedIn"]),
	},
	methods: {
    
		...mapActions("account", ["logout"]),
    logoutUser(){
      this.logout();
    }
		
	},
	components: {
    },
	created() {
	},
	mounted() {
	},
	watch: {},
};
</script>
