import store from "./index";
import { flightService } from "../services/flight.service";
const state = {
	flights: null
};

const getters = {
};

const actions = {

	getFlights: async function ({ commit },{ bookingId,type,count=null}) {
		var flights = await flightService.getFlights(bookingId,type,count).then(
			(flights) => {
				if(flights.payload){
					if(flights.payload.dictionaries)
					{
						flights.payload.dictionaries=JSON.parse(flights.payload.dictionaries);
					}
				
					state.flights=flights.payload;

				return flights.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return flights;
	},
	
	confirmFlight: async function ({ commit },{ bookingId,flight}) {
		var flights = await flightService.confirmFlight(bookingId,flight).then(
			(flights) => {
				if(flights.payload){
					if(flights.payload.dictionaries)
					{
						flights.payload.dictionaries=JSON.parse(flights.payload.dictionaries);
					}			
					
				return flights.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return flights;
	},
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
  };