<template >
  <div  @scroll="handleScroll">
    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">Staff Management</h1>
        <button v-if="havePermit(['ADD_OR_UPDATE_STAFF'])" @click="addNewStaff" role="button" class="btn btn-primary" style="background-color: var(--primary-color);"
          >
          Add New Staff
        </button>
    </div><!-- End Page Title -->
    
    <div class="filters-container">
      <div class="buttons-container">
        <div class="searchbar position-relative d-flex align-items-center">
          <input
              autocomplete="off"
              type="search"
              name="search"
              id="search"
              placeholder="Search staff by id, name, email or phone number"
              v-model="searchText"
              class="search-input"
              @keyup.enter="searchData"
          />
          <a role="button" class="search-icon" @click="searchData">
            <i class="fa fa-search"></i>
          </a>
        </div>
        <!-- <download-csv
            :data="filteredUsersList"
            name="users_export.csv"
            :fields="fieldsToExport"
            :labels="csvLabels"
        > -->
        <!-- <button
            class="add-btn dropdown-toggle"
            id="exportDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="exportUsersData()"
            :disabled="exportLoading"
            >
              <b-spinner v-if="exportLoading" small></b-spinner>
          <i v-if="!exportLoading" class="fas fa-file-csv me-2"></i>
          {{exportLoading?"&nbsp;Exporting CSV":"Export As CSV"}}
        </button> -->
       
      </div>
    </div>
    <div class="table-container">
      <table
          v-if="staffs && staffs.length > 0 && !isSearching"
          class="table table-hover table-borderless all-users-table"
      >
        <thead>
        <th style="width: 5%;" class="text-center">
          #
          <!--						<input-->
          <!--							type="checkbox"-->
          <!--							name="select_all"-->
          <!--							id="select_all"-->
          <!--							@click="toggleSelectAll()"-->
          <!--							:checked="isSelectedAll()"-->
          <!--						/>-->
        </th>
        <th style="width: 5%;">ID</th>
        <th style="width: 20%;">Name</th>
        <th style="width: 20%;">Email</th>
        <!-- <th style="width: 15%;">City/Country</th> -->
        <th style="width: 10%;">Phone Number</th>
        <th style="width: 10%;">Roles</th>
        <th style="width: 7.5%;">Status</th>
        </thead>
        <tbody>
        <tr
            @click="showViewEditUserModal($event, user)"
            v-for="(user, i) in staffs"
            :key="user.id"
        >
          <td class="text-center">
            {{ i + 1 }}
            <!--							<input-->
            <!--								@click="toggleUserSelect($event, user)"-->
            <!--								type="checkbox"-->
            <!--								name="select_1"-->
            <!--								:id="user.id"-->
            <!--								:checked="isChecked(user.id)"-->
            <!--							/>-->
          </td>
          <td>{{ user.id }}</td>
          <td class="user-name d-flex align-items-center" :id="`tooltip-target-${user.id }`">
            <img
                v-if="user.profilePicture"
                class="user-image"
                :src="user.profilePicture"
            />
            <div v-else class="user-avatar">
              {{ user.firstName[0] }}
            </div>
            {{ shortenedFullName(user.firstName, user.lastName) }}

            <b-tooltip :target="`tooltip-target-${user.id }`" triggers="hover">
              {{ user.firstName }} {{ user.lastName }}
            </b-tooltip>
          </td>
          <td>{{ user.email }}</td>
          <!-- <td>{{ dateParser(user.access_start_date) }}</td> -->
          <!-- <td>{{ dateParser( user.access_end_date) }}</td> -->
          <!-- <td>{{ user.pricing_plan }}</td> -->
          <!-- <td>{{ user.type }}</td> -->
          <!-- <td>{{ user.city }}, {{ user.countryCode }}</td> -->
          <td>{{ user.phone ? user.phone : "-" }}</td>
          <td> <div v-if="user.roles" v-html="mapRoles(user.roles)"></div> <div v-else>-</div></td>
          <td
              :class="`${
								user.status == 'Active'
									? 'success-text'
									: 'danger-text secondary'
							}`"
          >
            {{ user.status }}
          </td>
        </tr>
        </tbody>
      </table>

      <div
          v-else-if="!staffs && loading"
          class="d-flex align-items-center justify-content-center gap-3 p-3 font-500 m-5"
      >
        <b-spinner></b-spinner> Loading...
      </div>
      <h5 v-else-if="!loading && (!staffs || staffs.length<=0)" class="text-center p-4 m-0">No users found</h5>

      <div v-if="fetchingNextRows" class="text-center p-4">
        <b-spinner></b-spinner>
      </div>
      
      
    </div>
    <br/>
    <center>
      <button v-if="fetchingNextRows==false && isNextRecordAvailable==true" @click="fetchNextRows()" :class="['filter-user-type-btn']">
        Load More
      </button>
    </center>

    <!--		<b-pagination-->
    <!--			align="right"-->
    <!--			v-model="currentPage"-->
    <!--			:total-rows="rows()"-->
    <!--			:per-page="perPage"-->
    <!--		></b-pagination>-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {havePermit} from "./../../../helpers/FormatHelpers";
export default {
  name: "StaffManagement",
  components: {
  },
  data() {
    return {
      loading:false,
      currentPage: 1,
      // rows: 0,
      perPage: 10,
      searchText: "",
      selectedUsers: [],
      showNewUserModal: false,
      showUserDetailModal: false,
      showBulkUploadModal: false,
      createUserType: "",
      // productVariantData: null,
      userToViewEdit: {
        user: null,
        openTab: 0,
      },
      nextPageToken: undefined,
      pageSize: 25,
      searchString: undefined,
      fetchingNextRows: false,
      isSearching: false,
      exportLoading: false
    };
  },
  computed: {
    ...mapState("user", ["staffs","isNextRecordAvailable"]),
    // ...mapState("productVariant", [""]),
    offset() {
      return (this.currentPage - 1) * this.perPage;
    },
    filteredUsersList() {
      if (this.staffs && this.staffs.length > 0) {
        // this.searchText = this.searchText.spl
        var result = this.staffs.filter((user) => {
          return (
              ((user.firstName + " " + user.lastName || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.firstName || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.lastName || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.id || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.email || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.username || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.phone || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1) &&
              (this.userType == "all" ||
                  user.type.toLowerCase() == this.userType.toLowerCase()) &&
              (this.userStatus == "all" ||
                  user.status.toLowerCase() == this.userStatus.toLowerCase())
          );
        });
        return result;
      }
      return [];
    }
  },
  methods: {
    havePermit,
    ...mapActions("user", ["fetchPaginatedUsersList"]),
    addNewStaff(){
      this.$router.push({ path:"Staff/new" });
    },
    mapRoles(roles)
    {
      if(!roles)
        return null;

      var roles=JSON.parse(roles);
      return roles.map(x=>x.R[0]).map(x=>`<a href='/admin/role/?id=${x.ID}'>${x.Title}</a>`).join(", ");
    },
    rows() {
      if (this.staffs) {
        return this.filteredUsersList.length;
      } else return 0;
    },
    fetchNextRows() {
      if (!this.fetchingNextRows && this.isNextRecordAvailable) {
        this.fetchingNextRows = true;
        this.fetchPaginatedUsersList({type:'management',pageSize: this.pageSize, nextPageToken: this.nextPageToken, searchString: this.searchString}).then((result) => {
          this.fetchingNextRows = false;

          if(result && result.nextPageToken) {
            this.nextPageToken = result.nextPageToken;
          }
        }).catch((err) => {
          this.fetchingNextRows = false;
          console.log({err});
        });
      }
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (!this.fetchingNextRows && scrollTop + clientHeight >= scrollHeight) {
          this.fetchNextRows();
      }
    },
    searchData(e) {
      this.nextPageToken = undefined;
      this.searchString = this.searchText;
      this.fetchingNextRows = true;

      this.isNextRecordAvailable=true;
      this.isSearching = true;

      this.fetchPaginatedUsersList({pageSize: this.pageSize, nextPageToken: this.nextPageToken, searchString: this.searchString, type:'management'}).then((result) => {
        this.fetchingNextRows = false;
        this.isSearching = false;

        if(result && result.nextPageToken) {
          this.nextPageToken = result.nextPageToken;
        }
      }).catch((err) => {
        this.fetchingNextRows = false;
        this.isSearching = false;
        console.log({err});

      });
    },
   
    shortenedFullName(firstname, lastname) {
      let fullName = firstname + ' ' + lastname;

      return fullName ? fullName.length > 30 ? fullName.substring(0,28) + '...' : fullName : '';
    }
  },


  
  created() {
    console.log({ps: this.pageSize, npt: this.nextPageToken, searchStr: this.searchString});
    // if (!this.staffs) {
    this.fetchPaginatedUsersList( {type:'management',pageSize: this.pageSize, nextPageToken: this.nextPageToken}).then((result) => {
      // this.rows = this.staffs.length;

      console.log('result from action');
      console.log(result);

      if(result && result.nextPageToken) {
        this.nextPageToken = result.nextPageToken;
      }
    });
    // } else {
    // this.rows = this.staffs.length;
    // }
  },
  watch: {
    isNextRecordAvailable:function(){
        console.log("dist");
    },
    staffs: function () {
      console.log("users updated");
      if (this.userToViewEdit.user) {
        console.log("user to view", this.userToViewEdit.user);
        this.userToViewEdit.user = this.staffs.find(
            (user) => user.id == this.userToViewEdit.user.id
        );
        // this.rows = this.staffs.length;
        // console.log(
        // 	"user after update",
        // 	this.staffs[this.userToViewEdit.user.id]
        // );
      }
    },
  },
};
</script>

<style scoped>
.staffs-container {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding: 1.875rem;
  overflow-y: auto;
}

.title-container {
  display: flex;
  align-items: center;
}

.page-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
  display: inline-block;
  /* margin: 0; */
}

.add-btn {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
}

.add-btn:hover {
  box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
}

.all-users-table thead,
.all-users-table td {
  height: 4rem;
  vertical-align: middle;
}

.all-users-table td:first-of-type {
  padding-left: 0;
}

.all-users-table input[type="checkbox"] {
  margin-left: 1.5rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 1.25rem;
  height: 1.25rem;
}

.filters-container {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
}

.filters-container .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.filter-inputs {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.search-input {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  text-align: left;
  /* margin-bottom: 1rem; */
  /* font-weight: 500; */
  color: #000000;
  font-size: 1rem;
  column-gap: 0.75rem;
  position: relative;
  outline: none;

  /* transition: transform 0.3s; */
}

.search-button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
  text-decoration: none;
  color: #000000;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.buttons-container .searchbar {
  flex-grow: 1;
}

.search-icon {
  font-size: 18px;
  position: absolute;
  margin-right: 1rem;
  right: 0;
  color: #000000;
}

.export-btn {
  padding: 0.5rem 1rem;
}

.dropdown-item {
  cursor: pointer;
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  color: var(--color-white);
  background-color: #231f20;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  /* z-index: 100; */
}

.user-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  object-position: top;
  margin-right: 0.5rem;
}

.user-name {
  cursor: pointer;
}

.filter-user-type-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.filter-user-type-btn.active {
  border: 1px solid var(--primary-color);
}

td.success-text {
  color: var(--color-success);
}

td.danger-text {
  color: var(--color-danger);
}

/*table > thead > th:first-child, table > tbody > tr > td:first-child {*/
/*  margin-left: 1.5rem!important;*/
/*}*/
</style>
