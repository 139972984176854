import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";

import {
  POST_GET_BOOKING
} from "../constants/urls";
// import { REQUEST_OPTIONS } from "../constants/requestOptions";
import ApiHelpers from "../helpers/ApiHelpers";

import ConfigHelper from "../helpers/ConfigHelper";

export const bookingService = {
  saveAndUpdateBooking,
  saveBookingFlight,getBookingPackages,getPackage,getBookingTransport,getBookingTransportByID,
  getBookingZiyarats,getBookingZiyaratById,getBookingStays,getBookingStayById
};

async function saveAndUpdateBooking(bookingDetail) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING,
    "POST",
    JSON.stringify(bookingDetail),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function saveBookingFlight(bookingId,flights) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Flight`,
    "POST",
    JSON.stringify(flights),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function getBookingZiyarats(bookingId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Ziyarat`,
    "GET",
   null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}


async function getBookingZiyaratById(bookingId,ZiyaratId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Ziyarat/${ZiyaratId}`,
    "GET",
   null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function getBookingStayById(bookingId,StayId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Stay/${StayId}`,
    "GET",
   null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function getBookingTransport(bookingId,itinerary) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Transport?itinerary=${itinerary}`,
    "GET",
   null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}


async function getBookingTransportByID(bookingId,transportId) {
  debugger;
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Transport/${transportId}`,
    "GET",
   null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}


async function getBookingStays(bookingId,city) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING+`/${bookingId}/Stay?stayCity=${city}`,
    "GET",
   null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function getBookingPackages(bookingId) {
  // debugger;
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url+ `/Package?bookingId=${bookingId}`,
    "Get",
    null,
    true
  ).then(handleResponse);
  // debugger;
  console.log("response returned from API Helper", response);
  return response;
}

async function getPackage(packageId) {
  // debugger;
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url+ `/Package/${packageId}`,
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}
