import "./assets/css/style.css";
import Vue from "vue";

import VueRouter from 'vue-router';
import App from "./App.vue";
import router from "./router";

import store from "./store";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import { BootstrapVue } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueProgressBar from "vue-progressbar";
import { SkeletonPlugin } from "bootstrap-vue";
import Toasted from "vue-toasted";
import vSelect from "vue-select";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "./helpers/StringMethods";
import i18n from './i18n'
import VueLazyLoad from 'vue-lazyload';
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);
// Vue.component("vueDropzone", vue2Dropzone);
Vue.component("v-select", vSelect);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueLazyLoad);

Vue.use(VueRouter);
Vue.use(Toasted, {
	iconPack: "fontawesome",
	singleton: true,
});
Vue.use(SkeletonPlugin);
Vue.use(VueProgressBar, {
	color: "rgb(143, 255, 199)",
	failedColor: "red",
	height: "3px",
	thickness: "3px",
});
Vue.use(VueTelInput);
Vue.use(BootstrapVue);


library.add(faUserSecret);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App)
}).$mount("#app");
