function havePermit(permissions)
{
	if(permissions && permissions.length>0)
	{
		var userPermission = JSON.parse(localStorage.getItem("permissions"));
		var isPermit=false;
		permissions.forEach((p, i, arr) => {
			if (Object.values(userPermission).indexOf(p) >= 0) {
				isPermit = true;
			}
		});
		return isPermit;
	}
	return true;
}

function toCurrencyString(amount, currency, decimalPlaces) {
	return amount.toLocaleString(undefined, {
		style: "currency",
		currency: currency,
		maximumFractionDigits: decimalPlaces,
	});
}

function minToHoursAndMins(mins) {
	var hours = (mins / 60).toFixed(0);
	var minutes = mins - hours * 60;
	if (minutes < 10) {
		minutes = "0" + minutes.toString();
	}
	var output = "";
	return output + hours + ":" + minutes;
}

function toShortDateString(date) {
	var parsedDate = new Date(date).toDateString();
	parsedDate = parsedDate.substr(parsedDate.indexOf(" ") + 1);
	return parsedDate;
}
function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function formatDate(inputDate) {
	const months = [
		"Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	];

	const parts = inputDate.split("-");
	if (parts.length !== 3) {
		throw new Error("Invalid date format. Please use 'YYYY-MM-DD'.");
	}

	const year = parts[0];
	const month = months[parseInt(parts[1], 10) - 1];
	const day = parseInt(parts[2], 10);

	return `${day} ${month}`;
}
function getCurrency(currencyCode){
	if(!currencyCode)
	{
		return "";
	}
	if(currencyCode.toLowerCase()=='eur')
		return "€";
	if(currencyCode.toLowerCase()=='usd')
		return "$";
	if(currencyCode.toLowerCase()=='gbp')
		return "£";

		return currencyCode.toUpperCase();
}
function days(selectedDates){
	if(selectedDates && selectedDates.startDate && selectedDates.endDate)
	{
		return Math.ceil((selectedDates.endDate-selectedDates.startDate)/ (1000 * 3600 * 24));
	}
	return 0;
}
function getInputDateFormat(date, isDateTimeLocal = false) {
	var _Date = new Date(date),
		Day = _Date.getDate() > 9 ? _Date.getDate() : "0" + _Date.getDate(),
		Month =
			_Date.getMonth() + 1 > 9
				? _Date.getMonth() + 1
				: "0" + (_Date.getMonth() + 1);
	if (!isDateTimeLocal) {
		return _Date.getFullYear() + "-" + Month + "-" + Day;
	} else {
		var Hours =
			_Date.getHours() > 9 ? _Date.getHours() : "0" + _Date.getHours();
		var Minutes =
			_Date.getMinutes() > 9 ? _Date.getMinutes() : "0" + _Date.getMinutes();
		return (
			_Date.getFullYear() +
			"-" +
			Month +
			"-" +
			Day +
			"T" +
			Hours +
			":" +
			Minutes
		);
	}

	// 2018-06-12T19:30
}

function getTimeIn12Hours(date) {
	var Hours = new Date(date).getHours();
	var timeOfDay = parseInt(Hours) >= 12 ? "PM" : "AM";
	var Minutes = new Date(date).getMinutes();
	Hours = Hours <= 12 ? Hours : Hours - 12;
	return (
		Hours.toString().padStart(2, "0") +
		":" +
		Minutes.toString().padStart(2, "0") +
		" " +
		timeOfDay
	);
}

function parseForURL(string) {
	return string?.replace(/[^a-zA-Z0-9.-]/gi, "");
}

module.exports = {
	toCurrencyString,
	toShortDateString,
	getInputDateFormat,
	getTimeIn12Hours,
	minToHoursAndMins,
	parseForURL,
	getCurrency,
	numberWithCommas,days,
	formatDate,
	havePermit
};
