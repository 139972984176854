import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";

import {
  GET_TRANSPORTS
} from "../constants/urls";
// import { REQUEST_OPTIONS } from "../constants/requestOptions";
import ApiHelpers from "../helpers/ApiHelpers";

import ConfigHelper from "../helpers/ConfigHelper";

export const transportService = {
  getTransports, saveTransport, deleteTransport, getTransportOptions, saveTransportOption, deleteTransportOption
};


async function saveAndUpdateBooking(bookingDetail) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + POST_GET_BOOKING,
    "POST",
    JSON.stringify(bookingDetail),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function getTransports() {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_TRANSPORTS,
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function saveTransport(transport) {

  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_TRANSPORTS+(transport.id?'/update':''),
    "POST",
    JSON.stringify(transport),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function deleteTransport(transportId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_TRANSPORTS+"/"+transportId+"/delete",
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function getTransportOptions(transportId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_TRANSPORTS+"/"+transportId+"/option",
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function saveTransportOption(transportId, transportOption) {

  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
    url + GET_TRANSPORTS+"/"+transportId+"/option"+(transportOption.id?'/update':''),
    "POST",
    JSON.stringify(transportOption),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function deleteTransportOption(transportOptionId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
    url + GET_TRANSPORTS+"/option/"+transportOptionId+"/delete",
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}
