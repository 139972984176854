<template>
  <div>

    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">Transport Management</h1>
    </div><!-- End Page Title -->
    <hr />
    <section class="section dashboard">
      <div class="row mt-4">
        <div class="col-xl-3">
          <div v-for="transport in transports" class="mb-4">
            <div  @click="selectTransport(transport.id)" :class="'card info-card sales-card ' + ($route.query.id == transport.id ? 'active' : '')">
  
  
              <div class="card-body">
                <div style="display: flex;">
                  <h5 style="flex: 1;" class="card-title">{{ transport.name }} </h5>
                </div>
                <div class="d-flex align-items-center">
                  <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                    style="width: 40%; height: auto;">
                    <img :src="transport.iconImage" />
                  </div>
                  <div class="ps-2 ml-3 feature-text">
                    <div
                      v-if="transport.properties && readProps(transport.properties) && readProps(transport.properties).lugagge"
                      style="display: flex; " class="mb-3">
                      <i style="color: var(--primary-color); " class="fas fa-suitcase-rolling mr-3"></i>
                      <p>
                        Lugagge Up to <span style="color: var(--primary-color);">{{ readProps(transport.properties).lugagge
                        }}</span>
                      </p>
                    </div>
                    <div style="display: flex; " class="mb-3">
                      <i style="color: var(--primary-color); " class="fas fa-couch mr-3"></i>
                      <p>
                        Maximum <span v-if="transport.minSeater">{{ transport.minSeater }} -</span> {{ transport.maxSeater
                        }} Passengers
                      </p>
                    </div>
                    <div v-if="transport.properties" style="display: flex; "
                      v-for="feature in readFeatures(transport.properties)" class="mb-3">
                      <i style="color: var(--primary-color);" class="fas fa-star mr-3"></i>
                      <p>
                        {{ feature }}
                      </p>
                    </div>
  
                  </div>
                </div>
              </div>
       
            </div>
          </div>
        </div>
        <div class="col-xl-9">

          <div class="card" v-if="selectedVehicle" style="max-height: none;">
              <div class="card-body" style="flex: 1;" >
                <transportOptionsList :selectedTransport="selectedVehicle"></transportOptionsList>
                
              </div>
          </div>

      </div>
      </div>
   </section>




  </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
import VehicleCreationBox from "./../../../components/Admin/Vehicle/VehicleCreationBox.vue"
import jQuery from "jquery";
import transportOptionsList from "./../../../components/Admin/transport/transportOptionsList.vue"
import Swal from 'sweetalert2'
import Vue from "vue";
export default {
  name: "TransportManagement",
  data() {
    return {
      isAdding: false,
      editedVehicle: null
    };
  },
  computed: {
    ...mapState("transport", ["transports"]),
    selectedVehicle() {
            if (this.$route.query.id && this.transports) {
                return this.transports.find(x => x.id == this.$route.query.id);
            }
            return null;
    },
  },
  methods: {

    ...mapActions("transport", ["getTransports", "deleteTransport"]),
    selectTransport(id) {
            this.$router.push({ query: { id: id } });
    },        
    readFeatures(properties) {
      var prop = JSON.parse(properties);
      return prop["features"];
    },
    readProps(properties) {
      var prop = JSON.parse(properties);
      return prop;
    },
 
  },
  components: {
    VehicleCreationBox,
    transportOptionsList
  },
  created() {
    this.getTransports();
  },
  mounted() {
  },
  watch: {},
};
</script>
<style scoped>
.feature-text p {
  font-size: x-small;

}

.card {
  margin-bottom: 0px;
  max-height: 220px;
  overflow: auto;
  cursor: pointer;
}
.card.active{
  background-color: var(--primary-color);
}

.card.active h5{
  color: white;
}

.card.active p{
  color: white;
}

.card.active i{
  color: white !important;
}

</style>