import store from "./index";
import { bookingService } from "../services/booking.service";
const state = {
	currentBooking: null,
	packages:null,
	loading:false,
	ziyarats:null

};

const getters = {
};

const actions = {

	saveBooking: async function ({ commit }, bookingDetail) {
		var booking = await bookingService.saveAndUpdateBooking(bookingDetail).then(
			(booking) => {
				if(booking.payload){
				state.currentBooking=booking.payload;				
				return booking.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return booking;

	},
	saveBookingFlights: async function ({ commit }, {bookingId,flights}) {
		var booking = await bookingService.saveBookingFlight(bookingId,flights).then(
			(booking) => {
				if(booking.payload){
				state.currentBooking=booking.payload;				
				return booking.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return booking;

	},
	getBookingPackages: async function ({ commit }, bookingId) {
		var bookingPackages = await bookingService.getBookingPackages(bookingId).then(
			(booking) => {
				if(booking.payload){
					booking.payload.sort((a, b) => a.price.perTravellerAveragePrice-b.price.perTravellerAveragePrice);
				state.packages=booking.payload;				
				return booking.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingPackages;

	},
	getPackage: async function ({ commit }, {bookingId,packageId}) {
		state.loading=true;
		var bookingPackage = await bookingService.getPackage(packageId,bookingId).then(
			(booking) => {
				state.loading=false;
				if(booking.payload){	
					var packageIndex = state.packages.indexOf(state.packages.find(x=>x.id===booking.payload.id));
					if(packageIndex>=0)
					{
						var temp=state.packages;
						state.packages=null;
						setTimeout(function(){						
							temp[packageIndex]=booking.payload;
							state.packages=temp;
						},50)
					}else{
						var temp=state.packages;
						state.packages=null;
						setTimeout(function(){						
							temp.push(booking.payload);
							state.packages=temp;
						},50)
					}

					return booking.payload;
				}
			},
			(error) => {
				
				state.loading=false;
				return null;
			}
		);
		return bookingPackage;

	},
	getBookingTransports: async function ({ commit },{ bookingId, itinerary}) {
		var bookingTransports = await bookingService.getBookingTransport(bookingId, itinerary).then(
			(transports) => {
				if(transports.payload){
				state.transports=transports.payload;				
				return transports.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingTransports;

	},
	getBookingZiyarats: async function ({ commit },{ bookingId}) {
		var bookingZiyarats = await bookingService.getBookingZiyarats(bookingId).then(
			(ziyarats) => {
				if(ziyarats.payload){
				state.ziyarats=ziyarats.payload;				
				return ziyarats.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingZiyarats;

	},
	getBookingTransportById: async function ({ commit },{ bookingId, transportId}) {
		var bookingTransports = await bookingService.getBookingTransportByID(bookingId, transportId).then(
			(transports) => {
				if(transports.payload){				
				return transports.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingTransports;

	},
	getBookingStayById: async function ({ commit },{ bookingId, stayId}) {
		var bookingStay = await bookingService.getBookingStayById(bookingId, stayId).then(
			(stay) => {
				if(stay.payload){				
				return stay.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingStay;

	},
	getBookingZiyaratById: async function ({ commit },{ bookingId, ziyaratId}) {
		var bookingZiyarat = await bookingService.getBookingZiyaratById(bookingId, ziyaratId).then(
			(bookingZiyarat) => {
				if(bookingZiyarat.payload){				
				return bookingZiyarat.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingZiyarat;

	},getBookingStays: async function ({ commit },{ bookingId, city }) {
		var bookingStays = await bookingService.getBookingStays(bookingId,city).then(
			(stays) => {
				if(stays.payload){		
				return stays.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return bookingStays;

	},
	
};



const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
  };