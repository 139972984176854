import Vue from "vue";
import { rolesService } from "./../services/role.service";

const state = { loading: false };

const getters = {};

const actions = {
  async fetchRoles({ commit }) {
    commit("setLoading", true);
    const data = await rolesService.fetchRoles().then(
      (res) => {
        var data = res.payload;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async fetchUserRoles({ commit }, userid) {
    commit("setLoading", true);
    const data = await rolesService.fetchUserRoles(userid).then(
      (res) => {
        var data = res.payload;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async deleteRole({ commit }, id) {
    commit("setLoading", true);
    const data = await rolesService.deleteRole(id).then(
      (res) => {
        var data = res;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async getRolePermissions({ commit }) {
    commit("setLoading", true);
    const data = await rolesService.getRolePermissions().then(
      (res) => {
        var data = res.payload;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async addRole({ commit }, role) {
    commit("setLoading", true);
    const data = await rolesService.addRole(role).then(
      (res) => {
        var data = res;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async editRole({ commit }, role) {
    commit("setLoading", true);
    const data = await rolesService.editRole(role).then(
      (res) => {
        var data = res;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  
};

const mutations = {
  setLoading(state, data) {
    state.loading = data;
  },
};

function handleError(error) {
  // console.log(error);
  // Vue.toasted.error(error.message, {
  //   icon: "exclamation-triangle",
  //   theme: "outline",
  //   position: "bottom-center",
  //   duration: 3000,
  // });
  return Promise.reject(error);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};