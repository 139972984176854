<template>
  <div>

    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">Vehicle Management</h1>
    </div><!-- End Page Title -->
    <hr />
    <section class="section dashboard">
      <div class="row">
        <div v-for="transport in transports" class="col-xxl-3 col-md-4 mb-4">
          <div class="card info-card sales-card">


            <div class="card-body" v-if="editedVehicle!=transport.id">
              <div style="display: flex;">
                <h5 style="flex: 1;" class="card-title">{{ transport.name }} </h5>
                <a class="mt-4 mr-3" v-if="havePermit(['ADD_OR_UPDATE_VEHICLE'])" style="cursor: pointer;" @click="()=>{ isAdding=false; editedVehicle=transport.id;}"><i class="fas fa-pen text-primary"></i></a>
                <a class="mt-4" v-if="havePermit(['DELETE_VEHICLE'])" style="cursor: pointer;" @click="deleteVehicle(transport.id)"><i class="fas fa-trash text-danger"></i></a>
              </div>
              <div class="d-flex align-items-center">
                <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                  style="width: 40%; height: auto;">
                  <img :src="transport.iconImage" />
                </div>
                <div class="ps-2 ml-3 feature-text">
                  <div
                    v-if="transport.properties && readProps(transport.properties) && readProps(transport.properties).lugagge"
                    style="display: flex; " class="mb-3">
                    <i style="color: var(--primary-color); " class="fas fa-suitcase-rolling mr-3"></i>
                    <p>
                      Lugagge Up to <span style="color: var(--primary-color);">{{ readProps(transport.properties).lugagge
                      }}</span>
                    </p>
                  </div>
                  <div style="display: flex; " class="mb-3">
                    <i style="color: var(--primary-color); " class="fas fa-couch mr-3"></i>
                    <p>
                      Maximum <span v-if="transport.minSeater">{{ transport.minSeater }} -</span> {{ transport.maxSeater
                      }} Passengers
                    </p>
                  </div>
                  <div v-if="transport.properties" style="display: flex; "
                    v-for="feature in readFeatures(transport.properties)" class="mb-3">
                    <i style="color: var(--primary-color);" class="fas fa-star mr-3"></i>
                    <p>
                      {{ feature }}
                    </p>
                  </div>

                </div>
              </div>
            </div>
            <VehicleCreationBox :vehicleEdit="transport" v-else @closeAdding="() => { ;editedVehicle=null; }"></VehicleCreationBox>

          </div>
        </div>
        <div class="col-xxl-3 col-md-4 mb-4" v-if="havePermit(['ADD_OR_UPDATE_VEHICLE'])">
          <div class="card info-card sales-card" style="flex: 1; height: 100%; position: relative;">
            <div v-if="!isAdding" @click="() => { isAdding = true; editedVehicle=null; }" class="card-body"
              style="cursor: pointer; flex: 1; width: 100%; height: 100%;  text-align: -webkit-center; ">
              <center class="" style="padding-top: 18%">
                <img style="width: 100px; height: 100px;" src="../../../assets/images/icons8-add-100.png" />
              </center>
            </div>
            <VehicleCreationBox v-else @closeAdding="() => { isAdding = false; editedVehicle=null; }"></VehicleCreationBox>
          </div>
        </div>
      </div>
    </section>




  </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
import VehicleCreationBox from "./../../../components/Admin/Vehicle/VehicleCreationBox.vue"
import jQuery from "jquery";

import Swal from 'sweetalert2'
import {havePermit} from "./../../../helpers/FormatHelpers";
import Vue from "vue";
export default {
  name: "VehicleManagement",
  data() {
    return {
      isAdding: false,
      editedVehicle:null
    };
  },
  computed: {
    ...mapState("transport", ["transports"])
  },
  methods: {
    havePermit,

    ...mapActions("transport", ["getTransports","deleteTransport"]),
    readFeatures(properties) {
      var prop = JSON.parse(properties);
      return prop["features"];
    },
    readProps(properties) {
      var prop = JSON.parse(properties);
      return prop;
    },
    deleteVehicle(vehicleId) {
      var self=this;
      if (vehicleId) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            self.deleteTransport(vehicleId).then(()=>{
              self.getTransports();
            });
          }
        });

      }
    }

  },
  components: {
    VehicleCreationBox
  },
  created() {
    this.getTransports();
  },
  mounted() {
  },
  watch: {},
};
</script>
<style scoped>
.feature-text p {
  font-size: x-small;

}
.card{
  margin-bottom: 0px;
  max-height: 220px;
  overflow: auto;
}
</style>