import store from "./index";
import { ziyaratService } from "../services/ziyarat.service";
const state = {
	ziyarats: null
};

const getters = {
};

const actions = {

	getZiyarats: async function ({ commit }) {
		var ziyarats = await ziyaratService.getZiyarats().then(
			(ziyarats) => {
				if(ziyarats.payload){
					
				
					state.ziyarats=ziyarats.payload;

				return ziyarats.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return ziyarats;
	},
	getZiyaratById: async function ({ commit },ziyaratId) {
		var ziyarats = await ziyaratService.getZiyaratByID(ziyaratId).then(
			(ziyarats) => {
				if(ziyarats.payload){
					

				return ziyarats.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return ziyarats;
	},
	saveZiyarat: async function ({ commit }, ziyarat) {
		var self=this;
		var _ziyarat = await ziyaratService.saveZiyarat(ziyarat).then(
			(ziyarat) => {
				return ziyarat.payload;
				
			},
			(error) => {
				return null;
			}
		);
		return _ziyarat;
	},

	deleteZiyarat: async function ({ commit }, ziyaratId) {
		var ziyarat = await ziyaratService.deleteZiyarat(ziyaratId).then(
			(ziyarat) => {
				if(ziyarat.payload){
					
				

				return ziyarat.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return ziyarat;
	},
	
	saveZiyaratOffer: async function ({ commit },{ ziyaratId, ziyarat}) {
		var self=this;
		debugger;
		var _ziyarat = await ziyaratService.saveZiyaratOffer(ziyaratId, ziyarat).then(
			(ziyarat) => {
				return ziyarat.payload;
				
			},
			(error) => {
				return null;
			}
		);
		return _ziyarat;
	},
	deleteZiyaratOffer: async function ({ commit }, ziyaratOfferId) {
		var ziyarat = await ziyaratService.deleteZiyaratOffer(ziyaratOfferId).then(
			(ziyarat) => {
				if(ziyarat.payload){
					
				

				return ziyarat.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return ziyarat;
	},
	
	
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
  };