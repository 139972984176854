<template>
    <div class="mt-3">
        <div style="display: flex;">

            <h5 class="card-title mr-3" style="flex: 1;">Ziyarat Deals</h5>
            <button v-if="!isAdding && havePermit(['ADD_OR_UPDATE_ZIYARAT_DEAL'])" @click="() => {
                isAdding = true; editId = null;
            }" class="btn btn-primary mr-4"
                style="background: transparent; padding: 0px; border: 4px solid var(--primary-color); color: var(--primary-color); border-radius: 20px; width: 40px; height: 40px; ">
                <i class="fas fa-plus"></i>
            </button>

        </div>

        <div class="row" >
            <div v-for="offer in ziyaratDeals?ziyaratDeals.offers:[]" class="col-xxl-3 col-md-4 mb-4">
                <div class="card info-card sales-card" style="background: whitesmoke;">


                    <div class="card-body" v-if="editId != offer.id">
                        <div style="display: flex;">
                            <h5 style="flex: 1;" class="card-title">{{ offer.transport.name }} </h5>
                            <a v-if="havePermit(['ADD_OR_UPDATE_ZIYARAT_DEAL'])" class="mt-4 mr-3" @click="editDeal(offer.id)" style="cursor: pointer;"><i
                                    class="fas fa-pen text-primary"></i></a>
                            <a v-if="havePermit(['DELETE_ZIYARAT_DEAL'])" class="mt-4" @click="deleteZiyaratDealClick(offer.id)" style="cursor: pointer;"><i class="fas fa-trash text-danger"></i></a>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                                style="width: 40%; height: auto;">
                                <img :src="offer.transport.iconImage" />
                            </div>
                            <div class="ps-2 ml-3 feature-text">

                                <div style="display: flex; " class="mb-3">
                                    <p><b>Max <span v-if="offer.transport.minSeater">{{ offer.transport.minSeater }}
                                                -</span> {{ offer.transport.maxSeater
                                                }} Passengers</b>
                                    </p>
                                </div>
                                <div>
                                    <h6 style="padding-top: 5px; text-align: left; flex:1" class="mt-2 mr-3 pr-3">
                                        Price : <span>{{ offer.currency.toUpperCase() }} {{
                                            offer.price.toFixed(2) }}</span>
                                    </h6>

                                    <h6 v-if="offer.discount" style="padding-top: 5px; text-align: left; flex:1"
                                        class="mt-2 mr-3 pr-3">
                                        Discount : <span>{{ offer.currency.toUpperCase() }} {{
                                            offer.discount.toFixed(2) }}</span>
                                    </h6>
                                    <h4 style=" line-height: 15px; padding-top: 5px;" class="mt-2 mr-3 pr-3 price">
                                        {{ offer.currency.toUpperCase() }} {{ (offer.price -
                                            (offer.discount ? offer.discount : 0)).toFixed(2) }}<br />
                                        <small style="font-size: x-small;">Total Price</small>
                                    </h4>

                                    <!-- <button  class="mt-2 select-btn btn btn-primary">
							Select This Transport
						</button> -->
                                </div>
                                <!-- <div v-if="transport.properties" style="display: flex; "
                        v-for="feature in readFeatures(transport.properties)" class="mb-3">
                        <i style="color: var(--primary-color);" class="fas fa-star mr-3"></i>
                        <p>
                          {{ feature }}
                        </p>
                      </div> -->

                            </div>
                        </div>
                    </div>
                    <ZiyaratDealCreationBox :ziyaratOffer="offer" v-else
                        @closeZiyaratDeal="(isRefresh) => { if (isRefresh) { getZiyaratDeals(); } editId = null; isAdding = false; }"
                        :ziyaratObject="selectedZiyarat"></ZiyaratDealCreationBox>

                </div>     
            </div>
            <div class="col-xxl-3 col-md-4 mb-4" v-if="isAdding">
                <div class="card info-card sales-card" style="background: whitesmoke;">
                    <ZiyaratDealCreationBox
                        @closeZiyaratDeal="(isRefresh) => { if (isRefresh) { getZiyaratDeals(); } isAdding = false; }"
                        :ziyaratObject="selectedZiyarat"></ZiyaratDealCreationBox>
                </div>
            </div>



        </div>

    </div>
</template>
<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import LightBox from 'vue-image-lightbox';
import Swal from 'sweetalert2'
import Vue from "vue";
import ZiyaratDealCreationBox from "./ZiyaratDealCreationBox.vue";
import ziyaratAdd from "./ZiyaratAdd.vue"

import {havePermit} from "./../../../helpers/FormatHelpers";
export default {
    name: "ZiyaratDealList",
    data() {
        return {
            ziyaratDeals: null,
            isAdding: false,
            editId: null
        };
    },
    props: {
        selectedZiyarat: Object
    },
    computed: {

    },
    methods: {
        havePermit,
        ...mapActions("ziyarat", ["saveZiyarat", "deleteZiyarat", "getZiyaratById", "deleteZiyaratOffer"]),
        getZiyaratDeals() {
            if (this.selectedZiyarat) {
                this.getZiyaratById(this.selectedZiyarat.id).then((deals) => {
                    this.ziyaratDeals = deals;
                });
            }
        },
        deleteZiyaratDealClick(ziyaratOfferId) {
            var self = this;
            if (ziyaratOfferId) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.deleteZiyaratOffer(ziyaratOfferId).then(() => {
                            self.getZiyaratById(self.selectedZiyarat.id).then((deals) => {
                                self.ziyaratDeals = deals;
                            });
                        });
                    }
                });

            }
        },
        editDeal(id) {
            this.isAdding = false;
            this.editId = id;
        }
    },
    components: {
        ZiyaratDealCreationBox
    },
    created() {
        this.getZiyaratDeals();
    },
    mounted() {
    },
    watch: {
        selectedZiyarat: function () {
            this.ziyaratDeals = null;
            this.getZiyaratDeals();
        }
    },
};
</script>
<style scoped></style>