<template>
    <div style="height: 100vh; display: flex; flex-direction: column;">
        <!-- ======= Header ======= -->
        <header id="header" class="header fixed-top d-flex align-items-center">
      
          <div class="d-flex align-items-center justify-content-between">
            <a href="index.html" class="logo d-flex align-items-center">
              <img src="./../../assets/images/home/logo.png" style="max-height: 40px;" />         
              <span class="d-none d-lg-block">Dashboard</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn"></i>
          </div><!-- End Logo -->
          <div style="flex:1"></div>
          <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
      
      
              <li class="nav-item dropdown pe-3" v-if="user && loggedIn">
                <b-dropdown variant="link"  class="nav-link nav-profile d-flex align-items-center pe-0">
                  <template #button-content>
                    <img :src="user.profilePicture?user.profilePicture:'https://cdn.pixabay.com/photo/2012/04/26/19/43/profile-42914_640.png'" class="rounded-circle mr-3">
                    <span > {{user.firstName}} {{user.lastName}}</span>
                  </template>
                  <b-dropdown-item href="#">My Profile</b-dropdown-item>
                  <b-dropdown-item href="#">Account Settings</b-dropdown-item>
                  <b-dropdown-item @click="logoutUser">Sign Out</b-dropdown-item>
                </b-dropdown>
              </li><!-- End Profile Nav -->
      
            </ul>
          </nav><!-- End Icons Navigation -->
      
        </header><!-- End Header -->
      
        <!-- ======= Sidebar ======= -->
        <aside id="sidebar" class="sidebar">
      
          <ul class="sidebar-nav" id="sidebar-nav">
      
            <li class="nav-item">
              <a class="nav-link " href="index.html">
                <i class="bi bi-grid"></i>
                <span>Dashboard</span>
              </a>
            </li><!-- End Dashboard Nav -->
            <li class="nav-heading">Staff and Role Managment</li>
      
            <li class="nav-item">
              <a href="/admin/staff" class="nav-link collapsed" >
                <i class="fa fa-user"></i>
                <span>Staff Management</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/admin/role" class="nav-link collapsed" >
                <i class="fa fa-plus"></i>
                <span>Role Management</span>
              </a>
            </li><!-- End Profile Page Nav -->

            <li class="nav-heading">Transport and Ziyarat Managment</li>
      
            <li class="nav-item">
              <a href="/admin/vehicle" class="nav-link collapsed" >
                <i class="fa fa-car"></i>
                <span>Vehicles Management</span>
              </a>
            </li><!-- End Profile Page Nav -->

            <li class="nav-item">
              <a  href="/admin/transport" class="nav-link collapsed" >
                <i class="fas fa-shuttle-van"></i>
                <span>Transport Management</span>
              </a>
            </li><!-- End Profile Page Nav -->

            <li class="nav-item">
              <a  href="/admin/ziyarat" class="nav-link collapsed" >
                <i class="fas fa-mosque"></i>
                <span>Ziyarat Management</span>
              </a>
            </li><!-- End Profile Page Nav -->
          </ul>
      
        </aside><!-- End Sidebar-->
      
      
        <transition name="fade">
          <main id="main" class="main" style="flex: 1;">
          
            <router-view  />
            
          </main>
        </transition>
        
    
        <!-- ======= Footer ======= -->
        <footer id="footer" class="footer">
          <div class="copyright">
            &copy; Copyright <strong><span>Umrah Wallet</span></strong>. All Rights Reserved
          </div>
         
        </footer><!-- End Footer -->
      
      
    </div>
      
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
import "./../../assets/css/Admin/style.css";
import jQuery from "jquery";
import Vue from "vue";
export default {
	name: "Admin",
	data() {
		return {
		};
	},
	computed: {
		
		...mapState("account", ["user","loggedIn"]),
	},
	methods: {
    
		...mapActions("account", ["logout"]),
    logoutUser(){
      this.logout();
    }
		
	},
	components: {
    },
	created() {
	},
	mounted() {
	},
	watch: {},
};
</script>
