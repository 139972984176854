import Vue from "vue";
import router from "../router";
import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import { accountService } from "../services/account.service";

import User from "../models/user";
const user = JSON.parse(localStorage.getItem("user"));
const isToken = helpers.checkCookie("token");

if (!isToken) {
  localStorage.setItem("user", null);
}

const state =
  isToken && user
    ? {
        loggedIn: true,
        user
      }
    : {
        loggedIn: false,
        user: null,
        userCredentials: {
          username: null,
          password: null,
        },
      };

const actions = {
  async login({ commit }, { username, password }) {
    // try {
    commit("setLoading", true);
    const response = await accountService
      .login(username, password)
      .then((response) => {
        if (response) {
          state.loggedIn = true;
          state.user = new User(response.payload.user);
          localStorage.setItem("user", JSON.stringify(new User(response.payload.user)));
          return response.payload.user;
        }
      })
      .catch((error) => {
        handleError(error);
        // console.log(error);
        commit("loginFailure");
        return Promise.reject(error);
      });
    return response;
    
  },
  async adminlogin({ commit }, { username, password }) {
    // try {
    commit("setLoading", true);
    const response = await accountService
      .adminlogin(username, password)
      .then((response) => {
        if (response) {
          state.loggedIn = true;
          state.user = new User(response.payload.user);
          localStorage.setItem("user", JSON.stringify(new User(response.payload.user)));
          if(response.payload?.userRoles){
            var permissions=response.payload?.userRoles?.map(z=>z.permissions);
            var _p=[];
            permissions.forEach(pArray => {
               _p={..._p,...(pArray.map(x=>x.permissionCode))};
            });
            localStorage.setItem("permissions", JSON.stringify(_p));
          }
          return response.payload.user;
        }
      })
      .catch((error) => {
        handleError(error);
        // console.log(error);
        commit("loginFailure");
        return Promise.reject(error);
      });
    return response;    
  },
  async forgotPassword({ commit }, { email, redirectUrl }) {
    // try {
    commit("setLoading", true);
    const response = await accountService
      .forgotPassword(email,redirectUrl)
      .then((response) => {
        if (response) {
         
          return response.payload;
        }
      })
      .catch((error) => {
        handleError(error);
        // console.log(error);
       
        return Promise.reject(error);
      });
    return response;
    
  },
  async resetPassword({ commit }, { password, code }) {
    // try {
    commit("setLoading", true);
    const response = await accountService
      .resetPassword(code,password)
      .then((response) => {
        if (response) {
         
          return response.payload;
        }
      })
      .catch((error) => {
        handleError(error);
        // console.log(error);
       
        return Promise.reject(error);
      });
    return response;
    
  },
  logout({ commit }) {
    var type=this.state?.account?.user?.type;
    accountService.logout();
    commit("logout");
    if(type.toLowerCase()=="management")
    {
      router.replace("/admin/login").catch(() => {});    
    }else{
      router.replace("/account/login").catch(() => {});
    }
  },
  
  async register({ commit }, user) {
    debugger;
    const response = await accountService.register(user).then(
      (res) => {
        if (res) {
          const data = res.payload;
          return data;
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return response;
  },
};

const mutations = {
  // updateUserProperty(updated) {
  // 	state.user = { ...state.user, ...updated };
  // },
  // loginSuccess(state, user) {
  //   console.log("login successful");
  // },
  loginFailure(state) {
    state.loggedIn = false;
    state.user = null;
    state.loading = false;
    console.log("login unsuccessful");
  },
  logout(state) {
    state.loggedIn = false;
    state.user = null;
  },
  setLoading(state, data) {
    if (data) {
      console.log("users loading...");
    } else {
      console.log("users loading complete!");
    }
    state.loading = data;
  },
};

function handleError(error) {
  console.log({ error });
  if (error.errorCode == "149") {
    Vue.toasted.success("Email Verification Required", {
      theme: "outline",
      position: "bottom-center",
      duration: 5000,
    });
    return;
  } else if (error.errorCode == "150") {
    Vue.toasted.success("Phone Verification Required", {
      theme: "outline",
      position: "bottom-center",
      duration: 5000,
    });
    return;
  }

  Vue.toasted.error(error.message, {
    icon: "exclamation-triangle",
    theme: "outline",
    position: "bottom-center",
    duration: 3000,
  });
  return Promise.reject(error);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
