<template>
    <div>


        <div class="row mt-4">
            <div class="col-xl-3">

                <div v-for="ziyarat in fetchZiyarats" :class="'card ' + ($route.query.id == ziyarat.id ? 'active' : '')"
                    @click="selectZiyarat(ziyarat.id)">
                    <div class="card-body profile-card pt-4 d-flex flex-column">
                        <h2>{{ ziyarat.zayarat }}</h2>
                        <h6 class="tt-short" v-html="(ziyarat.description.length > 250 ? ziyarat.description.substring(0, 250) + '...' :
                            ziyarat.description)">
                        </h6>
                    </div>
                </div>
                <div :class="'card '" v-if="!isAddMode && havePermit(['ADD_OR_UPDATE_ZIYARAT'])">
                    <div class="card-body profile-card pt-4 d-flex flex-column">
                        <div @click="() => { isAddMode = true; $router.push({ query: { id: undefined } }); }"
                            class="card-body"
                            style="cursor: pointer; flex: 1; width: 100%; height: 100%;  text-align: -webkit-center; ">
                            <center class="" style="padding-top: 10%">
                                <img style="width: 100px; height: 100px;" src="../../../assets/images/icons8-add-100.png" />
                            </center>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-9">

                <div class="card" v-if="isAddMode || selectedZiyarat">
                    <ziyaratAdd :editedZiyarat="selectedZiyarat" :city="city" v-if="isAddMode"
                        @closeAdd="() => { isAddMode = false; }"></ziyaratAdd>
                    <div class="card-body pt-4" v-if="!isAddMode">
                        <b-tabs pills no-body v-if="selectedZiyarat">
                            <b-tab title="Overview" active>
                                <div style="display: flex;">

                                    <h5 class="card-title mr-3" style="flex: 1;">{{ selectedZiyarat.zayarat }}</h5>
                                    <button v-if="havePermit(['ADD_OR_UPDATE_ZIYARAT'])" @click="editZiyarat(selectedZiyarat.id)" class="btn btn-primary mr-4"
                                        style="background: transparent; padding: 0px; border: 4px solid var(--primary-color); color: var(--primary-color); border-radius: 20px; width: 40px; height: 40px; ">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                    <button v-if="havePermit(['DELETE_ZIYARAT'])"  @click="deleteZiyaratClick(selectedZiyarat.id)"
                                        class="btn btn-danger text-danger"
                                        style="background: transparent; padding: 0px; border: 4px solid ;  border-radius: 20px; width: 40px; height: 40px; ">
                                        <i class="fas fa-trash"></i>
                                    </button>

                                </div>
                                <div class="mbox">
                                    <VueEditor :editorToolbar="[]" :disabled="true" v-model="selectedZiyarat.description" />
                                </div>
                                <LightBox :showLightBox="true" :startAt="stp" v-if="showLightBox"
                                    @onClosed="() => { showLightBox = false; }" :media="mediaImages"></LightBox>
                                <h5 class="card-title mt-3">Images</h5>
                                <div class="row">
                                    <img class="col-md-4" style="cursor: pointer; object-fit: cover;"
                                        v-for="(img, ind) in JSON.parse(selectedZiyarat.images)" :src="img"
                                        @click="() => { stp = ind; showLightBox = true; }" />
                                </div>
                            </b-tab>
                            <b-tab title="Ziyarat Places" >
                                <div style="display: flex;">

                                    <h5 class="card-title mr-3" style="flex: 1;">Ziyarat Places</h5>
                                    <button @click="addPlace()"  v-if="havePermit(['ADD_OR_UPDATE_ZIYARAT'])" class="btn btn-primary mr-4"
                                        style="background: transparent; padding: 0px; border: 4px solid var(--primary-color); color: var(--primary-color); border-radius: 20px; width: 40px; height: 40px; ">
                                        <i class="fas fa-plus"></i>
                                    </button>

                                </div>

                                <div class="row mt-4"
                                    v-if="selectedZiyarat.included && JSON.parse(selectedZiyarat.included).length > 0">
                                    <div class="col-md-4 " v-for="include, ind in JSON.parse(selectedZiyarat.included)">
                                        <div
                                            style="background-color: whitesmoke; box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; border-radius: 10px; padding: 10px; margin-bottom: 20px; position: relative;">
                                            <h5 class="card-title" style="margin: 0px;">{{ include.name }}</h5>
                                            <p class="small fst-italic">{{ include.description }}</p>
                                            <button class="btn btn-danger" v-if="havePermit(['ADD_OR_UPDATE_ZIYARAT'])" @click="removePlace(ind)" style="    position: absolute;
                                            top: -13px;
                                            right: -10px;
                                            width: 30px;
                                            height: 30px;
                                            padding: 0px;
                                            border: 2px solid black;
                                            border-radius: 15px;"><i class="fas fa-minus"></i></button>

                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <center>
                                        <h5 class="card-title">No Record Found</h5>
                                    </center>
                                </div>

                            </b-tab>
                             <b-tab title="Ziyarat Deals">
                                <div>
                                    <ZiyaratDealList :selectedZiyarat="selectedZiyarat"></ZiyaratDealList>
                                </div>
                            </b-tab>
                        </b-tabs>






                    </div>
                </div>

            </div>
        </div>


    </div>
</template>
<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import LightBox from 'vue-image-lightbox';
import Swal from 'sweetalert2'
import Vue from "vue";
import ziyaratAdd from "./ZiyaratAdd.vue"
import {havePermit} from "./../../../helpers/FormatHelpers";
import ZiyaratDealList from "./ZiyaratDealList.vue";
export default {
    name: "ZiyaratList",
    data() {
        return {
            showLightBox: false,
            isAddMode: false,
            stp: 0
        };
    },
    props: {
        city: String
    },
    computed: {
        ...mapState("ziyarat", ["ziyarats"]),
        selectedZiyarat() {
            if (this.$route.query.id && this.ziyarats) {
                return this.ziyarats.find(x => x.id == this.$route.query.id);
            }
            return null;
        },
        mediaImages() {
            if (this.selectedZiyarat) {
                var images = JSON.parse(this.selectedZiyarat.images);
                return images.map(x => {
                    return {
                        src: x,
                        type: "image",
                        srcset: x
                    };
                })
            }
            return [];
        },
        fetchZiyarats() {
            return this.ziyarats?.filter(x => x.city.toLowerCase() == this.city.toLowerCase());
        }
    },
    methods: {
        havePermit,

        ...mapActions("ziyarat", ["saveZiyarat","deleteZiyarat", "getZiyarats"]),
        selectZiyarat(id) {
            this.isAddMode = false;
            this.$router.push({ query: { id: id } });
        },
        editZiyarat(id) {
            this.isAddMode = true;
            this.$router.push({ query: { id: id } });
        },
        deleteZiyaratClick(ziyaratId) {
            var self = this;
            if (ziyaratId) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!"
                }).then((result) => {
                    if (result.isConfirmed) {
                        self.deleteZiyarat(ziyaratId).then(() => {
                            self.getZiyarats();
                        });
                    }
                });

            }
        },
        removePlace(placeInd) {
            if (this.selectedZiyarat) {
                var places = JSON.parse(this.selectedZiyarat.included);
                places.splice(placeInd, 1);
                this.selectedZiyarat.included = JSON.stringify(places);
                
                this.saveZiyarat(this.selectedZiyarat);

            }
        },
        async addPlace() {
            const { value: formValues } = await Swal.fire({
                title: "Add Ziyarat Place",
                html: `<input id="ziyaratName" placeholder="Ziyarat Place" class="form-control mb-2"><br/><textarea id="ziyaratDesc" class="form-control"  placeholder="Ziyarat Place Description"></textarea>`,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: "Save",
                cancelButtonText: "cancel",                
                preConfirm: () => {
                    return [
                        document.getElementById("ziyaratName").value,
                        document.getElementById("ziyaratDesc").value
                    ];
                }
            });
            if (formValues) {
                if (formValues.length == 2 && formValues[0] && formValues[1]) {
                    var places = JSON.parse(this.selectedZiyarat.included);
                    places.push({ name:formValues[0] , description:formValues[1]  });
                    this.selectedZiyarat.included = JSON.stringify(places);
                    this.saveZiyarat(this.selectedZiyarat);
                }
            }
        }
    },
    components: {
        LightBox,
        ziyaratAdd,
        ZiyaratDealList
    },
    created() {
    },
    mounted() {
    },
    watch: {},
};
</script>
<style scoped>
.card.active {
    background: var(--primary-color);
    color: white;
}

.card.active h2 {
    color: white;
}

.card.active .tt-short {
    color: white;
}
</style>
<style>
.mbox .ql-toolbar.ql-snow {
    display: none;
}

.mbox .ql-container.ql-snow.ql-disabled {
    border: 0px;

}

.mbox .ql-editor.ql-blank {
    padding: 0px;
}

.nav-pills .nav-link.active {
    background-color: var(--primary-color);
}

.tt-short {
    font-size: 12px;
}

.tt-short h1 {
    font-size: 12px;
}

.tt-short h2 {
    font-size: 12px;
}

.tt-short p {
    font-size: 12px;
}

.card.active .tt-short span {
    color: white !important;
}

.card.active .tt-short p {
    color: white !important;
}

.card .tt-short h1 {
    display: none;
}

.card .tt-short h2 {
    display: none;
}

.card .tt-short h3 {
    display: none;
}

.tt-short h4 {
    display: none;
}

.tt-short blockquote {
    display: none;
}
</style>