<template >
  <div>
    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">You do not have permission to access this module</h1>
    </div><!-- End Page Title -->
     
  </div>
</template>

<script>
export default {
  name: "PermissionNotAssigned",
  components: {
  },
  data() {
    return {
    };
  },
  
};
</script>

<style scoped>

</style>
