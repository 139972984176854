
<template>
	<b-card v-if="modalShow" @click.stop :class="'cart-Model animate__animated ' + animateClass">
		
		<div class="text-center p-4" v-if="!cartItems || cartItems.length<=0">
			<img class="mt-5 mb-4" src="./../../assets/images/home/emptycart.svg" alt="">
			<h5>
				Your Cart is empty<small><br />You have no items in your cart Let’s go book your package!</small>
			</h5>
		</div>
		<div  class="p-4" v-else style="display: flex; flex-direction: column; height: inherit; padding: 0px !important;">
			<div  style="flex: 1; overflow: auto; padding: 15px;">
				<div v-if="packageCartItem" class="mb-4">
					<div v-if="packageCartItem.data">
						<div class="mb-4" style="display: flex;"><i style="color: var(--primary-color); font-size: 24px;" class="fas fa-cube mt-1 mr-3"></i> <h5>Flight + Makkah & Madina Hotels Package</h5></div>
						<div class="mb-2" v-if="packageCartItem.data.flight">
							<h6 class="mb-2"><i style="color: var(--primary-color);" class="fas fa-plane mt-1 mr-3"></i>Included Flights</h6>
							<div v-for="(itinerary, index) in packageCartItem.data.flight.data.itineraries" v-if="itinerary.segments && itinerary.segments.length > 0">
								<small v-if="index == 1" style="color: var(--primary-color);" ><b>Return Ticket</b></small>
								<p>{{ getAirportDetail()[itinerary.segments[0].departure.iataCode].City }}, {{ getAirportDetail()[itinerary.segments[0].departure.iataCode].CountryAlpha2 }}
									<i style="color: var(--primary-color);" class="fas fa-arrow-right mr-2 ml-2"></i>
									{{ getAirportDetail()[itinerary.segments[itinerary.segments.length - 1].arrival.iataCode].City }}, {{ getAirportDetail()[itinerary.segments[itinerary.segments.length - 1].arrival.iataCode].CountryAlpha2 }}
									<br/>
									<small style="color: var(--primary-color);">{{ new Date(itinerary.segments[0].departure.at).toUTCString() }}</small>
								</p>	
								<hr v-if="index == 0" style="margin-top: 5px; margin-bottom: 5px;"/>						
							</div>
						</div>
						<div class="mb-3" v-if="packageCartItem.data.hotels">
							<h6 class="mb-2"><i style="color: var(--primary-color);" class="fas fa-bed mt-1 mr-3"></i>Hotels Stays</h6>
							<div v-for="(hotel, index) in packageCartItem.data.hotels" >
								<p>
									<small v-if="hotel.hotel && hotel.hotel.address && hotel.hotel.address.address" style="color: var(--primary-color);" ><b>{{ hotel.hotel.address.address.city }}, {{ hotel.hotel.address.address.country }}</b></small>			
									<small v-else-if="hotel.hotel.iataCode.toLowerCase() == 'med'" style="color: var(--primary-color);" ><b>Madina</b></small>			
									<small v-else-if="hotel.hotel.iataCode.toLowerCase() == 'qca'" style="color: var(--primary-color);" ><b>Makkah</b></small>			
									<br/>
									{{ hotel.hotel.name }}
									<small v-if="hotel.hotel && hotel.hotel.address && hotel.hotel.address.address">
										<br/>
										{{ hotel.hotel.address.address.road }}, {{ hotel.hotel.address.address.city }}, {{ hotel.hotel.address.address.state }}
									</small>
								
									<small style="color: var(--primary-color);"  >		
										<br/>
										<b>						
											{{ new Date(hotel.offers[0].checkInDate).toDateString() }} - {{ new Date(hotel.offers[0].checkOutDate).toDateString() }}
									 	</b>
									</small>
									<small >		
										<br/>
										<b v-if="hotel.offers[0].roomQuantity > 0">						
											{{ hotel.offers[0].roomQuantity }}
									 	</b>
										 <b v-else>						
											1
									 	</b> &nbsp;
										Room<span v-if="hotel.offers[0].roomQuantity > 0">s</span>
										 - <b>{{ days(new Date(hotel.offers[0].checkInDate), new Date(hotel.offers[0].checkOutDate)) }}</b> Nights
									</small>
								</p>
								<hr v-if="index == 0" style="margin-top: 5px; margin-bottom: 5px;"/>						
						
							</div>
						</div>
						<div style="display: flex; align-items: end;">
							<h5 style="flex: 1;">
								Total Price <br/>
								<small>{{ days(new Date(packageCartItem.data.flight.data.itineraries[0].segments[0].departure.at), new Date(packageCartItem.data.flight.data.itineraries[packageCartItem.data.flight.data.itineraries.length-1].segments[packageCartItem.data.flight.data.itineraries[packageCartItem.data.flight.data.itineraries.length-1].segments.length-1].arrival.at)).toFixed(0) }} Nights & For {{packageCartItem.data.price.totalTraveller}} Travellers</small>
							</h5>
							<h4 style="color: var(--primary-color);">
								{{ getCurrency(packageCartItem.data.price.currency) }} {{ numberWithCommas(packageCartItem.data.price.grandTotal.toFixed(2)) }}
							</h4>
						</div>
					
					</div>
				</div>
				<hr v-if="packageCartItem" style="margin-top: 0px; height: 2px; margin-bottom: 0px; background-color: var(--primary-color);" />
				<div v-if="flightCartItem" class="mb-4">
					<div v-if="flightCartItem.data">
						<div class="mb-4" style="display: flex; align-items: center;"><i style="color: var(--primary-color); font-size: 20px; "  class="fas fa-plane mt-1 mr-3"></i> <h4>Flights</h4></div>
						<div class="mb-2" v-if="flightCartItem.data.data">
							<div v-for="(itinerary, index) in flightCartItem.data.data.itineraries" v-if="itinerary.segments && itinerary.segments.length > 0">
								<small v-if="index == 1" style="color: var(--primary-color);" ><b>Return Ticket</b></small>
								
								<p>{{ getAirportDetail()[itinerary.segments[0].departure.iataCode].City }}, {{ getAirportDetail()[itinerary.segments[0].departure.iataCode].CountryAlpha2 }}
									<i style="color: var(--primary-color);" class="fas fa-arrow-right mr-2 ml-2"></i>
									{{ getAirportDetail()[itinerary.segments[itinerary.segments.length - 1].arrival.iataCode].City }}, {{ getAirportDetail()[itinerary.segments[itinerary.segments.length - 1].arrival.iataCode].CountryAlpha2 }}
									<br/>
									<small style="color: var(--primary-color);">{{ new Date(itinerary.segments[0].departure.at).toUTCString() }}</small>
								</p>	
								<hr v-if="index == 0" style="margin-top: 5px; margin-bottom: 5px;"/>						
							</div>
						</div>					
						<div style="display: flex; align-items: end;"  v-if="flightCartItem && flightCartItem.data && flightCartItem.data.data && flightCartItem.data.data.price">
							<h6 style="flex: 1;" v-if="flightCartItem && flightCartItem.data && flightCartItem.data.data && flightCartItem.data.data.price">
								Total Price <br/>
								<small>{{flightCartItem.data.data.price.totalTravellers}} Travellers</small>
							</h6>
							<h5 style="color: var(--primary-color);">
								{{ getCurrency(flightCartItem.data.data.price.currency) }} {{ numberWithCommas( parseFloat(flightCartItem.data.data.price.grandTotal).toFixed(2)) }}
							</h5>
						</div>
					
					</div>
				</div>
				<hr v-if="flightCartItem" style="margin-top: 0px; height: 2px; margin-bottom: 0px; background-color: var(--primary-color);" class="mb-4" />
				<div v-if="stayCartItems && stayCartItems.length>0" class="mb-4">
					<div class="mb-4" style="display: flex; align-items: center;"><i style="color: var(--primary-color); font-size: 20px; "  class="fas fa-hotel mt-1 mr-3"></i> <h4>Hotel Stays</h4></div>
						
					<div v-for="(stayCartItem,index) in  stayCartItems" v-if="stayCartItem">
						<p>
							<small v-if="stayCartItem.data.hotel && stayCartItem.data.hotel.address && stayCartItem.data.hotel.address.address" style="color: var(--primary-color);" >
								<i class="fas fa-mosque"  v-if="stayCartItem.data.hotel.iataCode && stayCartItem.data.hotel.iataCode.toLowerCase() == 'med'"></i>
								<i class="fas fa-kaaba"  v-if="stayCartItem.data.hotel.iataCode && stayCartItem.data.hotel.iataCode.toLowerCase() == 'qca'"></i> &nbsp;
								<b>{{ stayCartItem.data.hotel.address.address.city }}, {{ stayCartItem.data.hotel.address.address.country }}</b></small>			
							<small v-else-if="stayCartItem.data.hotel.iataCode && stayCartItem.data.hotel.iataCode.toLowerCase() == 'med'" style="color: var(--primary-color);" ><b>Madina</b></small>			
							<small v-else-if="stayCartItem.data.hotel.iataCode && stayCartItem.data.hotel.iataCode.toLowerCase() == 'qca'" style="color: var(--primary-color);" ><b>Makkah</b></small>			
							<br/>
							{{ stayCartItem.data.hotel.name }}
							<small v-if="stayCartItem.data.hotel && stayCartItem.data.hotel.address && stayCartItem.data.hotel.address.address">
								<br/>
								{{ stayCartItem.data.hotel.address.address.road }}, {{ stayCartItem.data.hotel.address.address.city }}, {{ stayCartItem.data.hotel.address.address.state }}
							</small>
							
							<small style="color: var(--primary-color);" v-if="stayCartItem.data.offers[0].boardType && stayCartItem.data.offers[0].boardType.toLowerCase()=='breakfast'"  >		
								<br/>
								<i class="fas fa-utensils"></i> &nbsp; Breakfast Included	
							</small>
							<small style="color: var(--primary-color);"  >		
								<br/>
								<i class="fas fa-moon"></i>					
									{{ new Date(stayCartItem.data.offers[0].checkInDate).toDateString() }} - {{ new Date(stayCartItem.data.offers[0].checkOutDate).toDateString() }}
								
							</small>
						</p>
						<div style="display: flex; align-items: end;" class="mt-2">
							<h6 style="flex: 1;">
								Total Price								
							<small >		
								<br/>
								<b v-if="stayCartItem.data.offers[0].roomQuantity > 0">						
									{{ stayCartItem.data.offers[0].roomQuantity }}
								 </b>
								 <b v-else>						
									{{ stayCartItem.data.offers[0].roomQuantity?stayCartItem.data.offers[0].roomQuantity:"1" }}
								 </b> &nbsp;
								Room<span v-if="stayCartItem.data.offers[0].roomQuantity > 0">s</span>
								 - <b>{{ days(new Date(stayCartItem.data.offers[0].checkInDate), new Date(stayCartItem.data.offers[0].checkOutDate)) }}</b> Nights
							</small>
							</h6>
							<h5 style="color: var(--primary-color);">
								{{ getCurrency(stayCartItem.data.offers[0].price.currency) }} {{ numberWithCommas(stayCartItem.data.offers[0].price.total.toFixed(2)) }}
							</h5>
						</div>
						<hr v-if="index<stayCartItems.length-1" style="margin-top: 5px; margin-bottom: 5px;"/>						
						
					</div>
				</div>
				<hr v-if="stayCartItems && stayCartItems.length>0" style="margin-top: 0px; height: 2px; margin-bottom: 0px; background-color: var(--primary-color);" />
				<div v-if="transportCartItem" class="mt-4">
					<div v-if="transportCartItem.data">
						<div class="mb-4" style="display: flex; align-items: center;"><i style="color: var(--primary-color); font-size: 20px; " class="fas fa-car mt-1 mr-3"></i> <h4>Private Transport</h4></div>
						<div class="mb-3" v-if="transportCartItem.data">
							<h6 class="mb-2">Transport Includes</h6>
							<div v-for="(include, index) in transportCartItem.data.includes" style="display: flex;">
								<i style="margin-right: 10px; color: var(--primary-color);" class="fas fa-arrow-right"></i>
								
								<h6 class="m-0" style="font-size: small; font-weight: lighter; text-align: left; margin: 0; padding: 0px;">
							
									<span v-if="include.itenarary.toLowerCase()=='MedAirport-Med'.toLowerCase()">Al-Madinah al-Munawwarah Airport to Hotel</span>
									<span v-else-if="include.itenarary.toLowerCase()=='Med-MedAirport'.toLowerCase()">Al-Madinah al-Munawwarah Hotel to Airport</span>
									<span v-else-if="include.itenarary.toLowerCase()=='Mak-JedAirport'.toLowerCase()">Makkah al-Mukarramah Hotel to Jeddah Airport</span>
									<span v-else-if="include.itenarary.toLowerCase()=='JedAirport-Mak'.toLowerCase()">Jeddah Airport to Makkah al-Mukarramah Hotel</span>
									<span v-else-if="include.itenarary.toLowerCase()=='Mak-Med'.toLowerCase()">Makkah al-Mukarramah Hotel to Al-Madinah al-Munawwarah Hotel</span>
									<span v-else-if="include.itenarary.toLowerCase()=='Med-Mak'.toLowerCase()">Al-Madinah al-Munawwarah Hotel to Makkah al-Mukarramah Hotel</span>
									Transfer
								</h6>
								
							</div>
							<div style="display: flex; align-items: end;" class="mt-2">
								<h6 style="flex: 1;">
									Total Price <br/>
									<small>{{ transportCartItem.data.noOfVehicles }} Vehicles For {{ transportCartItem.data.totalTravellers }} Travellers</small>
								</h6>
								<h5 style="color: var(--primary-color);">
									{{getCurrency(transportCartItem.data.currency) }} {{ numberWithCommas(transportCartItem.data.totalPrice.toFixed(2)) }}
								</h5>
							</div>
						</div>
					</div>
				</div>
				<hr v-if="transportCartItem" style="margin-top: 0px; height: 2px; margin-bottom: 0px; background-color: var(--primary-color);" />
			
				<div v-if="ziyaratCartItems  && ziyaratCartItems.length>0" class="mt-4">
					<div  >
						<div class="mb-4" style="display: flex; align-items: center;">
							<i style="color: var(--primary-color); font-size: 20px; " class="fas fa-mosque mt-1 mr-3"></i> <h4>Ziyarat</h4></div>
							<div class="mb-2 pb-2" v-for="ziyaratCartItem in ziyaratCartItems"  v-if="ziyaratCartItem.data" style="display: flex; border-bottom: 0.2px solid lightgray;" >
								<i style="color: var(--primary-color); font-size: 20px; " class=" fas fa-check "></i>
								<div style="flex: 1;" class="ml-3">
								<h5>{{ ziyaratCartItem.data.zayarat }}</h5>
								<h6 v-if="ziyaratCartItem.data.offers" style="color: var(--primary-color);">
									On {{ new Date(ziyaratCartItem.data.on).toDateString() }} <span></span>
								</h6>
								<div style="display: flex; align-items: end;" class="mt-2">
									<h6 style="flex: 1;">
										Total Price <br/>
										<small>{{ ziyaratCartItem.data.offers.noOfVehicles }} Vehicles For {{ ziyaratCartItem.data.offers.totalTravellers }} Travellers</small>
									</h6>
									<h5 style="color: var(--primary-color);">
										{{ getCurrency(ziyaratCartItem.data.offers.currency) }} {{ numberWithCommas(ziyaratCartItem.data.offers.totalPrice.toFixed(2)) }}
									</h5>
								</div>
							</div>
							</div>
						</div>
				</div>
			</div>
			<div class="main-total-box">
				<h5>Total Price Of Complete Package</h5>
				<div style="display: flex; align-items: end;">
					<h6 style="flex: 1; color: darkgray; font-weight: lighter;" class="mt-2">
						<span v-if="packageCartItem"><i style="color: var(--primary-color);" class="fas fa-cube mt-1 mr-3"></i> Flight + Makkah & Madina Hotels Package</span>
						<br v-if="packageCartItem"/>
						<span v-if="flightCartItem"><i style="color: var(--primary-color);" class="fas fa-plane mt-1 mr-3"></i> Flights</span>
						<br v-if="flightCartItem"/>
						<span v-if="stayCartItems && stayCartItems.length>0"><i style="color: var(--primary-color);" class="fas fa-hotel mt-1 mr-3"></i>Hotels Stays</span>
						<br v-if="stayCartItems && stayCartItems.length>0"/>
						<span v-if="transportCartItem"><i style="color: var(--primary-color); " class="fas fa-car mt-1 mr-3"></i> Private Transport</span>
						<br v-if="transportCartItem"/>
						<span v-if="ziyaratCartItems && ziyaratCartItems.length>0"><i style="color: var(--primary-color); " class="fas fa-mosque mt-1 mr-3"></i> Ziyarats</span>
					
					</h6>
					<h4 style="color: var(--primary-color); text-align: right;">{{ getCurrency(currency) }} {{numberWithCommas(totalPrice.toFixed(2))}}</h4>
				</div>
				<hr style="margin-top: 10px; margin-bottom: 10px;"/>
				<div style="display: flex;">
					<button v-if="(packageCartItem || (flightCartItem && stayCartItems && stayCartItems.length>1))" @click="viewDetail" class="book-btn" >View Detal</button>
					<h5 style="text-align: right; flex: 1; "><span style="font-weight: lighter;">Average Price Per Person</span><br/>
						<b>{{ getCurrency(currency) }} {{numberWithCommas(totalAveragePrice.toFixed(2))}}</b></h5>				
				</div>
				
			</div>
		</div>
	</b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { numberWithCommas, getCurrency} from "./../../helpers/FormatHelpers.js"

export default {
	name: "CartModel",
	components: {
	},
	props: {
		isVisible: Boolean
	},
	data() {

		return {
			modalShow: false,
			animateClass: "animate__slideInRight"

		}
	},
	computed: {

		...mapState("booking", ["currentBooking"]),
		...mapState("cart", ["cartItems"]),
		flightCartItem(){
			if(this.cartItems && this.cartItems.length>0)
			{
				var flightInCart=this.cartItems.find(x=>x.type.toLowerCase()=="flight");
				return flightInCart;
			}
		},
		packageCartItem(){
			if(this.cartItems && this.cartItems.length>0)
			{
				var packageInCart=this.cartItems.find(x=>x.type.toLowerCase()=="package");
				return packageInCart;
			}
		},
		transportCartItem(){
			if(this.cartItems && this.cartItems.length>0)
			{
				var transport=this.cartItems.find(x=>x.type.toLowerCase()=="transport");
				return transport;
			}
		},
		ziyaratCartItems(){
			if(this.cartItems && this.cartItems.length>0)
			{
				var ziyarats=this.cartItems.filter(x=>x.type.toLowerCase()=="ziyarat");
				return ziyarats;
			}
		},
		stayCartItems(){
			if(this.cartItems && this.cartItems.length>0)
			{
				var stays=this.cartItems.filter(x=>x.type.toLowerCase()=="stay");
				return stays;
			}
		},
		totalPrice(){
			var price=0;
			if(this.packageCartItem)
			{
				price+=this.packageCartItem.data.price.grandTotal;
			}

			if(this.transportCartItem)
			{
				price+=this.transportCartItem.data.totalPrice;
			}
			
			if(this.ziyaratCartItems && this.ziyaratCartItems.length>0) 
			{
				this.ziyaratCartItems.forEach(item => {
					if(item && item.data && item.data.offers)
					{
						price+=item.data.offers.totalPrice;
					}
				});
			}

			if(this.flightCartItem && this.flightCartItem.data  && this.flightCartItem.data.data && this.flightCartItem.data.data.price)
			{
				price+= parseFloat( this.flightCartItem.data.data.price.grandTotal);
			}

			if(this.stayCartItems && this.stayCartItems.length>0) 
			{
				this.stayCartItems.forEach(stayCartItem => {
					if(stayCartItem && stayCartItem.data && stayCartItem.data.offers)
					{
						price+=stayCartItem.data.offers[0].price.total;
					}
				});
			}
			return price;
		},
		currency(){
			if(this.packageCartItem)
			{
				return this.packageCartItem.data.price.currency;
			}

			if(this.transportCartItem)
			{
				return this.transportCartItem.data.currency;
			}
			
			if(this.ziyaratCartItems && this.ziyaratCartItems.length>0) 
			{
				return this.ziyaratCartItems[0].data.offers.currency;
			}

			if(this.flightCartItem && this.flightCartItem.data  && this.flightCartItem.data.data && this.flightCartItem.data.data.price)
			{

				return this.flightCartItem.data.data.price.currency;
			}
			
		},
		totalAveragePrice(){
			var price=0;
			if(this.packageCartItem)
			{
				price+=this.packageCartItem.data.price.perTravellerAveragePrice;
			}

			if(this.transportCartItem)
			{
				price+=this.transportCartItem.data.perTravellerPrice;
			}
			if(this.ziyaratCartItems && this.ziyaratCartItems.length>0) 
			{
				this.ziyaratCartItems.forEach(item => {
					if(item && item.data && item.data.offers)
					{
						price+=item.data.offers.perTravellerPrice;
					}
				});
			}
			
			if(this.flightCartItem && this.flightCartItem.data  && this.flightCartItem.data.data && this.flightCartItem.data.data.price)
			{
				price+= parseFloat( this.flightCartItem.data.data.price.averagePerTravellerPrice);
			}
			
			if(this.stayCartItems && this.stayCartItems.length>0) 
			{
				this.stayCartItems.forEach(stayCartItem => {
					if(stayCartItem && stayCartItem.data && stayCartItem.data.offers)
					{
						price+=stayCartItem.data.offers[0].price.averagePerTravellerPrice;
					}
				});
			}
			return price;
		}


	},
	methods: {
		days(startDate,endDate){
			if(startDate && endDate)
			{
				return (endDate-startDate)/ (1000 * 3600 * 24);
			}
			return 0;
		},
		viewDetail(){
			
			this.$router.push({ params: { process: 'review' } });
		},
		getAirportDetail(){
			if(this.packageCartItem && 
			this.packageCartItem.data  && 
			this.packageCartItem.data.flight  && 
			this.packageCartItem.data.flight.dictionaries)
			{
				var dic=JSON.parse(this.packageCartItem.data.flight.dictionaries);
				return dic.airports?JSON.parse(dic.airports):{};
			}else if(this.flightCartItem && 
			this.flightCartItem.data  && 
			this.flightCartItem.data.dictionaries)
			{
				var dic=this.flightCartItem.data.dictionaries;
				return dic.airports?JSON.parse(dic.airports):{};
			}else{
				return {};
			}
		},
		
		numberWithCommas,getCurrency
	},
	mounted() {
	},
	watch: {
		isVisible() {
			if (!this.isVisible) {
				this.animateClass = "animate__slideOutRight";
				var self = this;
				setTimeout(function () {
					self.modalShow = false;
					self.animateClass = "animate__slideInRight";
				}, 400);
			} else {
				this.modalShow = true;
			}
		}
	},
	created() {

	},
};
</script>
<style scoped>
.cart-Model {
	overflow: auto;
	height: -webkit-fill-available;
	max-width: 350px;
	width: 100%;
	background: white;
	position: fixed;
	right: 0;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}
.cart-Model .card-body{
	
    height: inherit;
    padding: 0px;
	display: flex;
	flex-direction: column;
}
.main-total-box{
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	padding: 10px;
}

</style>