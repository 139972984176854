import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";

import {
  GET_ZIYARATS
} from "../constants/urls";
// import { REQUEST_OPTIONS } from "../constants/requestOptions";
import ApiHelpers from "../helpers/ApiHelpers";

import ConfigHelper from "../helpers/ConfigHelper";

export const ziyaratService = {
  getZiyarats,saveZiyarat,deleteZiyarat,getZiyaratByID,saveZiyaratOffer,deleteZiyaratOffer
};


async function getZiyarats() {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_ZIYARATS,
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function getZiyaratByID(ziyaratId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_ZIYARATS+"/"+ziyaratId,
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function saveZiyarat(ziyarat) {

  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_ZIYARATS+(ziyarat.id?'/update':''),
    "POST",
    JSON.stringify(ziyarat),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function deleteZiyarat(ziyaratId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_ZIYARATS+"/"+ziyaratId+"/delete",
    "Post",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}


async function saveZiyaratOffer(ziyaratId, ziyaratOffer) {

  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_ZIYARATS+'/'+ziyaratId+'/offer'+(ziyaratOffer.id?'/update':''),
    "POST",
    JSON.stringify(ziyaratOffer),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}
async function deleteZiyaratOffer(ziyaratOfferId) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
    url + GET_ZIYARATS+'/offer'+'/'+ziyaratOfferId+'/delete',
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
