import Vue from "vue";
import { userService } from "../services/user.service.js";


const state = {
	staffs: null,
	allUsers: null,
	isNextRecordAvailable: true,
};

const actions = {
	async createUser({ commit }, user) {
		const res = await userService.createUser(user).then(
			(response) => {
				var data = response.payload;
				debugger;
				if (!state.staffs) {
					state.staffs = [];
				}
				state.staffs.push(data);

				return data;
			},
			(error) => {
				// console.log(error);
				return handleError(error);
			}
		);
		return res;
	},
	async fetchPaginatedUsersList({ commit }, { type, pageSize, nextPageToken, searchString, status }) {

		var result = undefined;
		await userService.fetchPaginatedUsersList({ type, pageSize, nextPageToken, searchString, status }).then(
			(response) => {
				result = response;
				var data = response.payload;

				if (type) {

					if (type.toLowerCase() == "management") {
						if (nextPageToken) {
							state.staffs.concat(data.users);
						} else {
							state.staffs = data.users;
						}
					}
				}
				if (nextPageToken) {
					state.allUsers.concat(data.users);
				} else {
					state.allUsers = data.users;
				}

				if (data == null || data.users || data.users.length <= 0) {
					state.isNextRecordAvailable = false;
				} else {

					state.isNextRecordAvailable = true;
				}

			},
			(error) => {
				// console.log(error);
				handleError(error);
			}
		);

		return result;
	},

};

const mutations = {
	setManagements(state, data) {
		debugger;
		state.staffs = data;
	},
	appendManagements(state, data) {
		state.staffs.concat(data);
	},
	setUsers(state, data) {
		state.allUsers = data;
	},
	appendUsers(state, data) {
		state.allUsers = state.allUsers.concat(data);
	},
	nextRecordNotAvailable(state, data) {
		state.isNextRecordAvailable = false;
	},
	nextRecordAvailable(state, data) {
		state.isNextRecordAvailable = true;
	},
};

function handleError(error) {
	// console.log(error);
	Vue.toasted.error(error.message, {
		icon: "exclamation-triangle",
		theme: "outline",
		position: "bottom-center",
		duration: 3000,
	});
	return Promise.reject(error);
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
