import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import APIHelpers from "../helpers/ApiHelpers";

import {
  GET_FLIGHT
} from "../constants/urls";
// import { REQUEST_OPTIONS } from "../constants/requestOptions";
import ApiHelpers from "../helpers/ApiHelpers";

import ConfigHelper from "../helpers/ConfigHelper";

export const flightService = {
  getFlights, confirmFlight
};

async function getFlights(bookingId,type,count=null) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_FLIGHT+'?bookingId='+bookingId+'&type='+type+(count && count>0?`&maxFlights=${count}`:''),
    "Get",
    null,
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}
async function confirmFlight(bookingId,flight) {
  var url=await ConfigHelper.getAPIUrl();
  const response = await APIHelpers.makeAPICall(
     url + GET_FLIGHT+'?bookingId='+bookingId,
    "Post",
    JSON.stringify(flight),
    true
  ).then(handleResponse);
  console.log("response returned from API Helper", response);
  return response;
}

async function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // alert(response.error);
    var res = await response
      .json()
      .then(
        (data) =>
          (res = {
            error: data.error,
          })
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return Promise.reject(res.error);
  } else {
    return response.json();
  }
}
