<template>
    <div class="card-body pt-4" >
        <h5 class="card-title">{{editedZiyarat?'Edit':'Add'}} Ziyarat</h5>
                <input  v-model="ziyarat.name" class="form-control mt-2 mb-3" placeholder="Ziyarat Name"/>                
                <VueEditor v-model="ziyarat.description" />
                <h5 class="card-title">Images</h5>
                <input
                        style="display: none;"
					    type="file"
					    accept="image/*"
					    name="file"
					    id="image-upload"
                        @change="getFile"
					    >
                        <LightBox :showLightBox="true" :startAt="stp" v-if="showLightBox" @onClosed="()=>{showLightBox=false;}" :media="mediaImages"></LightBox>
                             
                <div class="row">
                    <div class="col-md-4" v-for="(img,ind) in images" >
                          <img  @click="()=>{ stp=ind; showLightBox=true; }"  style="cursor: pointer; object-fit: cover;"  :src="img" />
                          
                          <button class="btn btn-danger" @click="removeImage(ind)" style="    position: absolute;
                          top: -13px;
                          right: 0;
                          width: 30px;
                          height: 30px;
                          padding: 0px;
                          border-radius: 15px;"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="col-md-4">
                        <div @click="openDialog()" class="card info-card sales-card" style="flex: 1; height: 100%; position: relative; background-color: whitesmoke !important;">
                          <div class="card-body"
                            style="cursor: pointer; flex: 1; width: 100%; height: 100%;  text-align: -webkit-center; ">
                            <center class="" style="padding-top: 18%">
                              <img style="width: 100px; height: 100px;" src="../../../assets/images/icons8-add-100.png" />
                            </center>
                          </div>
                        </div>
                      </div>
                </div>
        <div style="text-align: right;">
            <button class="btn btn-primary" @click="saveZiyaratClick()" :disabled="!isSavable || loading" style="background-color: var(--primary-color);">Save</button>
            <button class="btn btn-danger ml-3" @click="closeZiyarat()">Cancel</button>
        </div>





    </div>
</template>
<script>
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import { mapState, mapActions, mapMutations } from "vuex";

import helpers from "./../../../helpers/CookieHelpers";
import ConfigHelper from "./../../../helpers/ConfigHelper";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import jQuery from "jquery";
import LightBox from 'vue-image-lightbox';
import Swal from 'sweetalert2'
import Vue from "vue";
export default {
    name: "ZiyaratAdd",
    data() {
        return {
            ziyarat:{},
            images:[],            
            showLightBox:false,
            loading:false,
            stp:0
        };
    },
    props:{
        city:String,
        editedZiyarat: Object
    },
    computed: {
        mediaImages() {
            if (this.images) {
                var images= this.images;
                return images.map(x=>{
                    return { 
                    src: x,
                    type:"image",
                    srcset: x
                    };
                })
            }
            return [];
        },
        isSavable(){
            return this.ziyarat && this.ziyarat.name && this.ziyarat.description && this.images.length>0;
        }
    },
    methods: {
        ...mapActions("ziyarat",["saveZiyarat","getZiyarats"]),
        openDialog(){
            document.getElementById("image-upload").click();
        },
        
        async getFile(e) {
            var self=this;
            const [file] = e.target.files;
			if (file) {
				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "vehicle");
				formData.append("type", "Image");
				formData.append("IsCompressionRequired", true);
                
                var uploadurl = (await ConfigHelper.getAPIUrl())+"/file/upload";
                // Set up a handler for when the request completes
                const xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    if (xhr.status === 200) {
                            var response = JSON.parse(xhr.responseText);
                            if (response && response.payload) {
                                self.images.push(response.payload);
                            }
                        
                    }
                };

                xhr.open('POST', uploadurl,);
                xhr.setRequestHeader("Authorization", `Bearer ${helpers.getCookie("token")}`);
                xhr.send(formData);
			}
			console.log("file: ", e.target.files);
		},
        removeImage(ind){
            this.images.splice(ind,1);
        },
        saveZiyaratClick(){
            var self=this;
            this.loading=true;
            this.saveZiyarat({
                "id": this.editedZiyarat?.id,
                "zayarat": this.ziyarat.name,
                "description": this.ziyarat.description,
                "included": "[]",
                "data": "{}",
                "city": this.city,
                "images": JSON.stringify(this.images)
            }).then((ziyarat)=>{
                self.loading=true;
                self.getZiyarats();
                self.ziyarat.name="";
                self.ziyarat.description="";
                
                self.$router.push({ query: null });
                setTimeout(function(){
                self.$router.push({ query: { id: ziyarat.id } });
                },500);
                self.closeZiyarat();
            })
        },
        closeZiyarat(){
            this.$emit("closeAdd");
        }
    },
    components: {
        VueEditor , LightBox
    },
    created() {
        if(this.editedZiyarat)
        {
            this.ziyarat.name = this.editedZiyarat.zayarat;
            this.ziyarat.description= this.editedZiyarat.description;
            if(this.editedZiyarat.images)
            {
                this.images = JSON.parse(this.editedZiyarat.images);
            }
        }
    },
    mounted() {
    },
    watch: {},
};
</script>
<style scoped>
.card.active {
    background: var(--primary-color);
    color: white;
}

.card.active h2 {
    color: white;
}

.card.active small {
    color: white;
}
</style>
<style>
.nav-pills .nav-link.active {
    background-color: var(--primary-color);
}
</style>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>