import Vue from "vue";
import Vuex from "vuex";
import booking from "./booking.module";
import flight from "./flight.module";
import cart from "./cart.module";
import account from "./account.module";
import transport from "./transport.module";
import ziyarat from "./ziyarat.module";
import user from "./user.module";
import roles from "./role.module";
Vue.use(Vuex);
//Create store
export default new Vuex.Store({
  modules: { booking, flight, cart, account, transport, ziyarat, user, roles },
});
