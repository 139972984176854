<template>
    <div>
        <h5 class="card-title">{{ roleData.title }} Permissions</h5>
        <table class="table table-hover table-borderless mb-3" v-for="(group, index) in distinctGroups"
            :key="index">
            <thead>
                <tr>
                    <th>
                        <input type="checkbox" name="select_all" class="me-3" :id="group" @click="toggleSelectAll(group)"
                            :checked="getFilteredPermissions(permissions, group).length ==
                                getGroupSelectedPermissionsCount(group)
                                " />
                        {{
                            group
                            ? group +
                            " (" +
                            getGroupSelectedPermissionsCount(group) +
                            ")"
                            : "Other"
                        }}
                    </th>
                </tr>
                <!-- <tr class="d-flex">
                      <th class="" style="width: 3rem">
                      </th>
                      <th class="col">Name</th>
                      <th class="col">Description</th>
                    </tr> -->
            </thead>
            <tbody v-if="permissions && permissions.length > 0">
                <tr class="invoice-item d-flex" v-for="(permission, index) in getFilteredPermissions(
                        permissions,
                        group
                    )" :key="index">
                    <td class="" style="width: 3rem" colspan="1">
                        <input type="checkbox" name="select_all" :id="permission.code"
                            @click="toggleSelect(permission.code, group)" />
                    </td>
                    <td class="col" colspan="1">
                        {{ permission.name ? permission.name : "-" }}
                    </td>
                    <td class="col" colspan="1">
                        {{
                            permission.description ? permission.description : "-"
                        }}
                    </td>
                </tr>
            </tbody>
            <div v-else-if="loading" class="d-flex align-items-center justify-content-center gap-2 p-3 font-500">
                <b-spinner small></b-spinner> Loading...
            </div>
            <div v-else class="no-content">No Permissions found</div>
        </table>
        <div style="text-align: right;">
            <button class="btn btn-primary" @click="addOrUpdate()" style="background-color: var(--primary-color);">Save Permissions</button>
        </div>


        
    </div>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";

export default {
    name: "RolePermission",
    data() {
        return {
            selectedPermissions: [],
            permissions: [],
            roleTitle: null,
        };
    },
    computed: {
        ...mapState("roles", ["loading"]),

      

        modalBtnText() {
            return this.mode == "edit"
                ? "Update"
                : this.mode == "add"
                    ? "Create"
                    : "";
        },

        distinctGroups() {
            const uniqueGroups = new Set(
                this.permissions.map((permission) => permission.group)
            );
            return Array.from(uniqueGroups);
        },

        // roleTitle() {
        //   if (this.mode == "edit" && this.roleData) {
        //     return this.roleData.title;
        //   }
        //   return null;
        // },
    },
    props: {
        roleData: Object,
    },
    methods: {
        ...mapActions("roles", ["fetchRoles","getRolePermissions", "addRole", "editRole"]),

        closeModal() {
            this.$emit("updateModalState", false);
        },

        async getPermissions() {
            var _this = this;
            await _this.getRolePermissions().then((res) => {
                if (res) {
                    //   _this.permissions = res.map((x) => {
                    //     if (x) {
                    //       var obj = {};
                    //       obj.text = x;
                    //       obj.value = x;
                    //       return obj;
                    //     }
                    //   });
                    _this.permissions = res;
                }
            });
        },

        getFilteredPermissions(permissions, groupName) {
            if (permissions && groupName) {
                return permissions.filter(
                    (x) => x.group && x.group.toLowerCase() == groupName.toLowerCase()
                );
            }
            return permissions.filter((x) => !x.group);
        },

        getGroupSelectedPermissionsCount(group) {
            var permissions = this.permissions.filter(
                (x) => group && group.toLowerCase() == x.group.toLowerCase()
            );

            if (this.selectedPermissions && permissions) {
                var array = permissions.filter((x) =>
                    this.selectedPermissions.includes(x.code)
                );

                return array.length;
            }

            return "0";
        },

        toggleSelectAll(group) {
            var data = this.permissions.filter((x) => x.group == group);

            if (document.getElementById(group).checked == true) {
                data.forEach((x) => {
                    if (!this.selectedPermissions.includes(x.code)) {
                        this.selectedPermissions.push(x.code);
                    }
                    document.getElementById(x.code).checked = true;
                });
            } else {
                data.forEach((x) => {
                    document.getElementById(x.code).checked = false;
                    const index = this.selectedPermissions.indexOf(x.code);
                    if (index > -1) {
                        this.selectedPermissions.splice(index, 1);
                    }
                });
            }
        },

        toggleSelect(permissionCode, group) {
            if (document.getElementById(permissionCode).checked == false) {
                const index = this.selectedPermissions.indexOf(permissionCode);
                if (index > -1) {
                    this.selectedPermissions.splice(index, 1);
                }
            } else {
                this.selectedPermissions.push(permissionCode);
            }

            if (
                group &&
                this.selectedPermissions &&
                this.selectedPermissions.length
            ) {
                var data = this.permissions.filter((x) => x.group == group);

                for (let i = 0; i < data.length; i++) {
                    if (!this.selectedPermissions.includes(data[i].code)) {
                        document.getElementById(group).checked = false;
                        return;
                    }
                }

                document.getElementById(group).checked = true;
            }
        },

        addOrUpdate() {
            if (!this.roleData) {
                return;
            }

            var obj = {
                ...this.roleData,
                permissions: [],
            };

            if (this.selectedPermissions && this.selectedPermissions.length > 0) {
                var permissions = [];
                this.selectedPermissions.forEach((x) => {
                    permissions.push({ PermissionCode: x });
                });

                obj.permissions = permissions;
            }

                obj.roleID = this.roleData.id;

                this.editRole(obj)
                .then((res) => {
                    if (res) {
                        this.showMessage("success", "Role Permissions Save Successful");
                    }
                    this.$emit("onUpdateRoles",true);
                })
                .catch((err) => {
                    this.showMessage("error", "Some error occurred");
                });

        },

        showMessage(status, message) {
            if (status == "success") {
                Vue.toasted.success(message, {
                    theme: "outline",
                    position: "bottom-center",
                    duration: 5000,
                });
            } else if (status == "error") {
                Vue.toasted.error(message, {
                    icon: "exclamation-triangle",
                    theme: "outline",
                    position: "bottom-center",
                    singleton: true,
                    duration: 3000,
                });
            }
        },
        // search() {
        //   if (this.searchText) {

        //     this.permissions.filter(function (object) {
        //       if (searchText in object) {
        //         // if the object contains the dynamic key
        //         return true; // include the object in filtered result
        //       } else {
        //         // or else
        //         return false; // don't include the object in filtered result
        //       }
        //     });
        //   }
        // },
    },
    async created() {
        await this.getPermissions();
            if (this.roleData.permissions && this.roleData.permissions.length > 0) {
                var filtered = [];
                this.roleData.permissions.forEach((x) => {
                    filtered.push(x.permissionCode);
                });
                this.selectedPermissions = filtered;

                this.selectedPermissions.forEach((x) => {
                    document.getElementById(x).checked = true;
                });

            }

            if (this.roleData && this.roleData.title) {
                this.roleTitle = this.roleData.title;
            }
        
    },
    watch: {
        roleData:function(){
            this.selectedPermissions=[];
            var self=this;
            setTimeout(() => {
                    
                var filtered = [];

                self.roleData.permissions.forEach((x) => {
                    filtered.push(x.permissionCode);
                });
                self.selectedPermissions = filtered;

                self.selectedPermissions.forEach((x) => {
                    document.getElementById(x).checked = true;
                });

            }, 100);
           

        }
        // password: function () {
        // 	if (this.pwdError) {
        // 		this.pwdError = false;
        // 		this.errorText = "";
        // 	}
        // },
    },
    components: {},
};
</script>
  
<style scoped>
.create-role-modal .form-input {
    border: 0;
    border-bottom: 1.5px solid #b2b2b2;
    font-size: 1rem;
    outline: none;
    padding: 0 0 0.875rem;
    width: 100%;
    border-radius: 0;
    box-shadow: none !important;
    position: relative;
    height: 40px;
}

.permissions-div {
    column-gap: 6rem;
    flex-wrap: wrap;
}

.b-form-checkbox {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.b-form-checkbox>input,
.b-form-checkbox>label {
    margin: 0px;
}

.permissions-div-parent {
    height: 270px;
    overflow: auto;
}

/*  */
table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

/* th,
  td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  } */
thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
}

tbody {
    display: block;
    /* height: 350px;  Set the height you desire */
    overflow-y: auto;
}

/*  */
.enrolled-users-table td {
    max-height: 4rem;
    min-height: 2rem;
    height: unset;
    vertical-align: middle;
    max-height: 375px;
    overflow: scroll;
}

.invoice-item {
    cursor: pointer;
}

.add-enroll-title {
    text-transform: capitalize;
}

/*  */

/*  */

/* tbody {
    height: 280px;
  } */

.table-parent-div {
    max-height: 370px;
    overflow: scroll;
}

.search-input {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    width: 100%;
    text-align: left;
    color: #000000;
    font-size: 1rem;
    column-gap: 0.75rem;
    position: relative;
    outline: none;
}

.search-input:focus {
    outline: 0px !important;
    outline-offset: 0px !important;
}
</style>