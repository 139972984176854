
<template>
    
            <div class="card-body">
                <div style="display: flex;">
                    <input type="text" style="flex: 1; border: 0px; margin-right: 10px; margin-top: 5px; padding-left: 5px;"
                        placeholder="Vehicle Name" v-model="vehicleName" class="form-control card-title" />
                    <!-- <h5 style="flex: 1;" class="card-title">{{ transport.name }} </h5> -->
                    <a class="mt-4" @click="()=>{$emit('closeAdding',true);}" style="cursor: pointer;"><i class="fa fa-times text-danger"></i></a>
                </div>
                <div class="d-flex align-items-center">
                    <input
                        style="display: none;"
					    type="file"
					    accept="image/*"
					    name="file"
					    id="image-upload"
                        @change="getFile"
					    >
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"
                        style="width: 40%; height: max-content;">
                        <img  @click="openDialog()" v-if="!thumbnailMediaPath" src="./../../../assets/images/upload-icon-25.png" width="50" height="50" />
                        <img  @click="openDialog()" v-else-if="thumbnailMediaPath" :src="thumbnailMediaPath" />
                    </div>
                    <div class="ps-2 ml-3 feature-text">
                        <div style="display: flex; align-items: center; " class="mb-3">
                            <i style="color: var(--primary-color); " class="fas fa-suitcase-rolling mr-3"></i>
                            <input type="text" class="form-control" v-model="lugagge" placeholder="Max Lugagge Size"
                                style="font-size: x-small; padding: 2px; height: 20px;" />

                        </div>
                        <div style="display: flex; align-items: center; " class="mb-3">
                            <i style="color: var(--primary-color); " class="fas fa-couch mr-3"></i>
                            <div style="display: flex; align-items: center; ">
                                <input type="number" v-model="minSeater" class="form-control" placeholder="Max"
                                    style="font-size: x-small; flex:1 padding: 2px; height: 20px;" />
                                <i class="fas fa-arrows-alt-h ml-1 mr-1"></i>
                                <input type="number" v-model="maxSeater" class="form-control" placeholder="Min"
                                    style="font-size: x-small; flex:1 padding: 2px; height: 20px;" />

                            </div>

                        </div>
                        <div style="display: flex; align-items: center; " v-for="(feature,index) in features" class="mb-3">
                            <i style="color: var(--primary-color); " class="fas fa-star mr-3"></i>
                            <div style="display: flex; align-items: center; ">
                                <input v-model="features[index]" type="text" class="form-control" placeholder="feature"
                                    style="font-size: x-small; flex:1 padding: 2px; height: 20px;" />
                                <a @click="removeFeature(index)"><i class="fas fa-times text-danger ml-2"></i> </a>
                            </div>
                        </div>
                        <button @click="addFeature()" class="btn btn-priamry" style="padding: 2px; font-size: x-small; width: 100%;">Add
                            Feature</button>
                            <button @click="addVehicle()"  :disabled="(!isValidate) || loading" class="btn btn-secondary mt-2" style="padding: 2px; font-size: x-small; width: 100%;">
                                Save Vehicle</button>
                    </div>
                </div>
            </div>


      
</template>
<script>

import helpers from "./../../../helpers/CookieHelpers";
import ConfigHelper from "./../../../helpers/ConfigHelper";
import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import Vue from "vue";
export default {
    name: "VehicleCreationBox",
    props:{
        vehicleEdit:Object
    },
    data() {
        return {
            vehicleName:null,
            features:[],
            minSeater:null,
            maxSeater:null,
            lugagge:null,
            thumbnailMediaPath:null,
            loading:false
        };
    },
    computed: {
        ...mapState("transport", ["transports"]),
        vehicleObject(){
            return {
                "id":this.vehicleEdit?this.vehicleEdit.id:undefined,
                "name": this.vehicleName,
                "noOfSeaters": this.maxSeater,
                "minSeater": this.minSeater,
                "maxSeater": this.maxSeater,
                "properties": JSON.stringify({ features:this.features, lugagge:this.lugagge }),
                "iconImage": this.thumbnailMediaPath,
                "images":"[]"
            };
            
        },
        isValidate(){
            return this.vehicleObject.name && this.vehicleObject.noOfSeaters && this.vehicleObject.maxSeater && this.vehicleObject.iconImage && (this.features.length==0 || this.features.filter(x=>!x).length==0);
        }
    },
    methods: {

        ...mapActions("transport", ["saveTransport","getTransports"]),
        addFeature(){

            this.features.push("");
        },
        removeFeature(index){
            this.features.splice(index,1);
        },
        openDialog(){
            document.getElementById("image-upload").click();
        },
        
        async getFile(e) {
            var self=this;
            const [file] = e.target.files;
			if (file) {
				this.thumbnailMediaPath = URL.createObjectURL(file);
				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "vehicle");
				formData.append("type", "Image");
				formData.append("IsCompressionRequired", true);
                
                var uploadurl = (await ConfigHelper.getAPIUrl())+"/file/upload";
                // Set up a handler for when the request completes
                const xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        setTimeout(function () {
                            var response = JSON.parse(xhr.responseText);
                            if (response && response.payload) {
                                self.thumbnailMediaPath = response.payload;
                            }
                        }, 2000);
                    } else {
                       this.thumbnailMediaPath=null;
                    }
                };

                xhr.open('POST', uploadurl,);
                xhr.setRequestHeader("Authorization", `Bearer ${helpers.getCookie("token")}`);
                xhr.send(formData);
			}
			console.log("file: ", e.target.files);
		},
        addVehicle(){
            var self=this;
            this.loading=true;
            this.saveTransport(this.vehicleObject).then((transport)=>{
                self.$emit('closeAdding',true);
                self.getTransports();
                self.loading=false;
                self.vehicleName=null;
                self.features=[];
                self.minSeater=null;
                self.maxSeater=null;
                self.lugagge=null;
                self.thumbnailMediaPath=null;
            });
        }
    },
    components: {
    },
    created() {
        if(this.vehicleEdit)
        {
           this.vehicleName = this.vehicleEdit.name;
           this.minSeater = this.vehicleEdit.minSeater;
           this.maxSeater = this.vehicleEdit.maxSeater;
           this.thumbnailMediaPath = this.vehicleEdit.iconImage;
           if(this.vehicleEdit.properties)
           {
                var prop = JSON.parse(this.vehicleEdit.properties);
                this.features = prop.features;
                this.lugagge = prop.lugagge;
           }
        }
    },
    mounted() {
    },
    watch: {},
};
</script>