<template >
  <div style="display: flex; flex-direction: column;">
    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">Add Staff Management</h1>
    </div><!-- End Page Title -->

    <div class="filters-container" style="flex: 1;">
      <div id="page1">
        <div class="mb-4">
          <span class="add-pricing-sub-title"> Basic Information  </span>
        </div>
        <form>
          <div class="row">
            <div class="col-md-8">
              <div class="inline-group row">
                <div class="col">
                  <label for="firstName" class="main-label">First Name (required)</label>
                  <input type="search" class="form-input" id="firstName" v-model="firstName"
                    placeholder="Enter First Name here..." @change="setUsername" />
                </div>
                <div class="col">
                  <label for="lastName" class="main-label">Last Name (required)</label>
                  <input type="search" class="form-input" id="lastName" v-model="lastName"
                    placeholder="Enter Last Name here..." @change="setUsername" />
                </div>
              </div>
              <div class="inline-group row">
                <div class="col">
                  <label for="email" class="main-label">Email (required)</label>
                  <input type="email" class="form-input" id="email" v-model="email" placeholder="Enter Email here..." />
                </div>
                <div class="col">
                  <label for="mobile" class="main-label">Phone Number (required)</label>
                  <vue-tel-input :class="`form-input ${isPhoneError && showError ? 'error' : ''
                    }`" defaultCountry="PK" :inputOptions="telInputOptions" validCharactersOnly v-model="mobile"
                    @input="validate" @blur="showError = true" @focus="showError = false" :autoFormat="true"
                    mode="national" invalidMsg="Please enter a valid phone number" id="mobile"></vue-tel-input>
                  <p class="text-danger" v-if="showError && isPhoneError">
                    Invalid Phone Number
                  </p>
                </div>
              </div>
            </div>
            <div class="col-2 ps-5">
              <label class="main-label">Profile Picture (optional)</label>
              <div :class="['upload-btn-wrapper', { preview: profilePicturePath }]">
                <img :class="[{ 'd-none': !profilePicturePath }]" id="preview" :src="profilePicturePath" alt="preview" />
                <div v-if="profilePicturePath" class="right-menu">
                  <a role="button" class="right-menu-icon" @click="openDialog">

                    <i class="fa fa-edit"></i>
                  </a>
                  <a @click="removeFile" role="button" class="right-menu-icon delete">
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <button v-if="!profilePicturePath" type="button" class="upload-btn" @click="openDialog">
                  upload
                </button>
                <input class="form-input" type="file" accept="image/*,video/*" name="image-upload" id="image-upload"
                  @change="getFile" />
              </div>
            </div>
          </div>

          <div class="mb-4 mt-4">
            <span class="add-pricing-sub-title"> Login Information </span>
          </div>
          <div class="inline-group row">
            <div class="col">
              <label for="username" class="main-label">Username (required)</label>
              <input type="search" class="form-input" id="username" v-model="username"
                placeholder="Enter Username here..." />
            </div>

            <div class="col">
              <label for="role" class="main-label">Role</label>
              <v-select multiple class="form-input" id="role" v-model="managementRole" placeholder="Select Role..."
                :options="roleOptions" :appendToBody="true" />
            </div>
          </div>
          <div class="inline-group align-items-end row">
            <div class="col position-relative">
              <label for="password" :class="['main-label', { 'text-danger': pwdError }]">Password (required)</label>
              <input :type="showPassword ? 'text' : 'password'" :class="['form-input', { 'text-danger': pwdError }]"
                id="password" v-model="password" placeholder="Enter Password here..." />
              <i @click="toggleShowPassword" :class="[
                'fas input-icon-right',
                { 'fa-eye-slash': showPassword, 'fa-eye': !showPassword },
              ]"></i>
            </div>
            <div class="col-2">
              <button @click="generatePassword" type="button" class=" btn btn-dark">
                Generate
              </button>
            </div>
          </div>

        </form>
        <p v-if="pwdError" class="error-text text-danger">{{ errorText }}</p>
      </div>
      <div class="step-btn-container mt-4 " style="text-align: right;">

        <button @click="next" role="button" class="btn btn-primary" style="background-color: var(--primary-color);"
          :disabled="loading || disableNext">
          <b-spinner small v-if="loading"></b-spinner> Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ConfigHelper from "../../../../helpers/ConfigHelper";

import {havePermit} from "./../../../../helpers/FormatHelpers";
import helpers from "../../../../helpers/CookieHelpers";
export default {
  name: "AddStaffManagement",
  components: {
  },
  data() {
    return {
      loading: false,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
      username: null,
      mobile: null,
      phone: null,
      city: null,
      country: null,
      managementRole: null,
      // education: null,
      roleOptions: [],
      profilePicturePath: null,
      profilePictureType: "image",
      // role: null,
      address: null,
      errorText: "",
      pwdError: false,
      telInputOptions: {
        autocomplete: "on",
        maxlength: 15,
        tabindex: 10,
        placeholder: "Phone Number (required)",
        mode: "national",
      },
      showPassword: false,
      isPhoneError: false,
      showError: false,
    };
  },
  computed: {
    disableNext() {
      if (this.isPhoneError) {
        return true;
      }
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.password &&
        this.phone
      ) {
        return false;
      } else return true;

    }
  },
  methods: {
    ...mapActions("roles", ["fetchRoles", "deleteRole", "addRole"]),
    ...mapActions("user", ["createUser"]),

    removeFile() {
      this.profilePicturePath = null;
    },
    async getRoles() {
      this.fetchRoles().then((res) => {
        if (res) {
          this.roleOptions = res.map(x => { return { id: x.id, value: x.id, label: x.title }; });
        }
      });
    },
    generatePassword() {
      var length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.password = retVal;
      // return retVal;
    },
    withPopper(dropdownList, component, { width }) {
      // console.log(dropdownList, component.$refs.toggle, { width });
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList);

      return () => popper.destroy();
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    openDialog() {
      $("#image-upload").click();
    },
    async getFile(e) {
      const [file] = e.target.files;
      if (file) {
        this.profilePicturePath = URL.createObjectURL(file);
        document.getElementById("preview").title = file.name;
        this.profilePictureType = file.type.split("/")[0];
        let formData = new FormData();
        formData.append("file", file);
        formData.append("entity", "vehicle");
        formData.append("type", "Image");
        formData.append("IsCompressionRequired", true);

        var uploadurl = (await ConfigHelper.getAPIUrl()) + "/file/upload";
        // Set up a handler for when the request completes
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.status === 200) {
            setTimeout(function () {
              var response = JSON.parse(xhr.responseText);
              if (response && response.payload) {
                self.profilePictureType = response.payload;
              }
            }, 2000);
          } else {
            this.profilePictureType = null;
          }
        };

        xhr.open('POST', uploadurl,);
        xhr.setRequestHeader("Authorization", `Bearer ${helpers.getCookie("token")}`);
        xhr.send(formData);

      }
      console.log("file: ", e.target.files);
    },
    validate(num, obj) {
      // console.log(num, obj);
      if (obj.valid != null && obj.valid === false) {
        this.isPhoneError = true;
        return;
      }
      this.isPhoneError = false;
      if (obj.country && obj.country.iso2) {
        this.country = obj.country.iso2;
        this.phone = obj.number;
      }
      return false;
    },
    setUsername() {
      if (this.firstName && this.lastName)
        var length = 4,
          charset =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          randomCode = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        randomCode += charset.charAt(Math.floor(Math.random() * n));
      }
      this.username =
        this.firstName.trim().replace(/ +/g, "_").toLowerCase() +
        "_" +
        this.lastName.trim().replace(/ +/g, "_").toLowerCase() +
        "_" +
        randomCode;
    },
    next() {
      var self=this;
      var newUser = {
        phone: this.phone,
        email: this.email,
        username: this.username,
        password: this.password,
        countryCode: this.country,
        city: this.city,
        extraproperties: JSON.stringify(this.extraproperties),
        firstName: this.firstName,
        lastName: this.lastName,
        profilePicturePath: this.profilePicturePath,
        address: this.address,
        type: this.createUserType,
        isActive: true,
      };
      
        newUser.roles = this.managementRole.map(x=>x.id);
      
      console.log(newUser);
      this.createUser(newUser).then((response) => {
        console.log(response);
        if (response) {
          self.$router.push({ path:"/admin/staff"});
        }
      });

    },
  },



  created() {
    this.getRoles();
  },
  watch: {
  },
};
</script>

<style scoped>
.staffs-container {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding: 1.875rem;
  overflow-y: auto;
}

.title-container {
  display: flex;
  align-items: center;
}

.page-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
  display: inline-block;
  /* margin: 0; */
}

.add-btn {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
}

.add-btn:hover {
  box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
}

.all-users-table thead,
.all-users-table td {
  height: 4rem;
  vertical-align: middle;
}

.all-users-table td:first-of-type {
  padding-left: 0;
}

.all-users-table input[type="checkbox"] {
  margin-left: 1.5rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 1.25rem;
  height: 1.25rem;
}

.filters-container {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
}

.filters-container .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.filter-inputs {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.search-input {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  text-align: left;
  /* margin-bottom: 1rem; */
  /* font-weight: 500; */
  color: #000000;
  font-size: 1rem;
  column-gap: 0.75rem;
  position: relative;
  outline: none;

  /* transition: transform 0.3s; */
}

.search-button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
  text-decoration: none;
  color: #000000;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.buttons-container .searchbar {
  flex-grow: 1;
}

.search-icon {
  font-size: 18px;
  position: absolute;
  margin-right: 1rem;
  right: 0;
  color: #000000;
}

.export-btn {
  padding: 0.5rem 1rem;
}

.dropdown-item {
  cursor: pointer;
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  color: var(--color-white);
  background-color: #231f20;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  /* z-index: 100; */
}

.user-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  object-position: top;
  margin-right: 0.5rem;
}

.user-name {
  cursor: pointer;
}

.filter-user-type-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.filter-user-type-btn.active {
  border: 1px solid var(--primary-color);
}

td.success-text {
  color: var(--color-success);
}

td.danger-text {
  color: var(--color-danger);
}

/*table > thead > th:first-child, table > tbody > tr > td:first-child {*/
/*  margin-left: 1.5rem!important;*/
/*}*/
.confirm-modal-body {
  padding: 1rem !important;
  font-size: 1.125rem;
}

.body-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 100%;
}

.add-enroll-title {
  text-transform: capitalize;
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  margin: 0.5rem 0 1rem;
  color: #000000;
}

.main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0 0 0.875rem;
  /* max-width: 21.625rem; */
  width: 100%;
  border-radius: 0;
  box-shadow: none !important;
  position: relative;
  height: 40px;
}

.upload-btn-wrapper {
  width: 11rem;
  max-width: 11rem;
  max-height: 11rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
  box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper.preview {
  padding: 0;
}

#preview {
  max-width: 100%;
}

.upload-btn {
  padding: 0.5rem 1.25rem;
  font-weight: bold;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 6px;
  text-transform: uppercase;
  color: #000000;
  font-weight: normal;
}

.input-icon-right {
  position: absolute;
  font-size: 1.25rem;
  right: 0rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.upload-btn-wrapper .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.upload-btn-wrapper:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.upload-btn-wrapper .right-menu-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.8px solid #cbcbcb;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 50%;
}

.upload-btn-wrapper .right-menu-icon img {
  width: 1rem;
  height: 1rem;
}

.upload-btn-wrapper .right-menu-icon.delete {
  border-color: #ff0000;
}

#profilePicturePath {
  pointer-events: none;
}

.form-input.error {
  color: var(--color-danger) !important;
  border-color: var(--color-danger) !important;
}
</style>
