<template>
  <div>

    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">Ziyarat Management</h1>
    </div><!-- End Page Title -->
    <hr />
   
    <section class="section profile">
        <b-tabs pills no-body>
          <b-tab title="Makkah ziyarats" active> <ZiyaratList city="makkah"></ZiyaratList></b-tab>
          <b-tab title="Madina ziyarats"> <ZiyaratList city="madina"></ZiyaratList></b-tab>
        </b-tabs>
        <br/>
     
    </section>




  </div>
</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";
import jQuery from "jquery";
import ZiyaratList from "./../../../components/Admin/ziyarat/ZiyaratList.vue"
import Swal from 'sweetalert2'
import Vue from "vue";
export default {
  name: "ZiyaratManagement",
  data() {
    return {
    };
  },
  computed: {
    ...mapState("ziyarat", ["ziyarats"])
  },
  methods: {    
    ...mapActions("ziyarat", ["getZiyarats"]),
  },
  components: {
    ZiyaratList
  },
  created() {
    this.getZiyarats();
  },
  mounted() {
  },
  watch: {},
};
</script>
