
async function getAPIUrl(
) {	return "https://umrahwallet-api.devoxian.com";
}
async function getCurrencies(){
    return ["gbp"];
}
function getCities(
	) {	return [{ "city": "London", "iata_code": "LHR", "airport_name": "London Heathrow Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Manchester", "iata_code": "MAN", "airport_name": "Manchester Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Birmingham", "iata_code": "BHX", "airport_name": "Birmingham Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Liverpool", "iata_code": "LPL", "airport_name": "Liverpool John Lennon Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Leeds", "iata_code": "LBA", "airport_name": "Leeds Bradford Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    
    // Cities in Scotland
    { "city": "Edinburgh", "iata_code": "EDI", "airport_name": "Edinburgh Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Scotland" },
    { "city": "Glasgow", "iata_code": "GLA", "airport_name": "Glasgow Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Scotland" },
    { "city": "Aberdeen", "iata_code": "ABZ", "airport_name": "Aberdeen International Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Scotland" },
    { "city": "Inverness", "iata_code": "INV", "airport_name": "Inverness Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Scotland" },
    { "city": "Dundee", "iata_code": "DND", "airport_name": "Dundee Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Scotland" },

    // Cities in Wales
    { "city": "Cardiff", "iata_code": "CWL", "airport_name": "Cardiff Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Wales" },
    { "city": "Swansea", "iata_code": "SWS", "airport_name": "Swansea Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Wales" },
    { "city": "Newport", "iata_code": "QQY", "airport_name": "Newport Heliport", "country_code": "GB", "country_name": "United Kingdom", "state": "Wales" },
    { "city": "Bangor", "iata_code": "BAN", "airport_name": "Bangor Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Wales" },

    // Cities in Northern Ireland
    { "city": "Belfast", "iata_code": "BFS", "airport_name": "Belfast International Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Northern Ireland" },
    { "city": "Lisburn", "iata_code": "LSB", "airport_name": "Lisburn Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Northern Ireland" },
    { "city": "Derry", "iata_code": "LDY", "airport_name": "City of Derry Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "Northern Ireland" },

    // Additional Cities
    { "city": "Oxford", "iata_code": "OXF", "airport_name": "London Oxford Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Cambridge", "iata_code": "CBG", "airport_name": "Cambridge Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Nottingham", "iata_code": "EMA", "airport_name": "East Midlands Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Southampton", "iata_code": "SOU", "airport_name": "Southampton Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },
    { "city": "Bristol", "iata_code": "BRS", "airport_name": "Bristol Airport", "country_code": "GB", "country_name": "United Kingdom", "state": "England" },

	{ "city": "Jeddah", "iata_code": "JED", "airport_name": "King Abdulaziz International Airport", "country_code": "SA", "country_name": "Saudia Arabia", "state": "Mecca" },
    { "city": "Madina", "iata_code": "MED", "airport_name": "Prince Mohammad Bin Abdulaziz International Airport", "country_code": "SA", "country_name": "Saudia Arabia", "state": "Madina" }
    
    
];
	}
	

export default {
	getAPIUrl,
	getCities,
    getCurrencies
	
};
