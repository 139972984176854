<template>
	<div @click="()=>{ isShowCart=false }" :class="($route.path=='/' || $route.path=='/Home' || $route.path=='home')?'':'no-home'">
		<!--[if lte IE 9]>
		<p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade
			your browser</a> to improve your experience and security.</p>
		<![endif]-->

		<!-- main-menu Start -->
		<header v-if="!$route.meta.admin" class="top-area"  >
			<div class="header-area">
				<div class="container">
					<div class="row" style="display: flex;">
						<div class="col-4 col-md-2">
							<div class="logo">
								<a href="/">
									<img src="./assets/images/home/logo.png" style="max-height: 50px;" >
								</a>
							</div><!-- /.logo-->
						</div><!-- /.col-->
						<div style="flex: 1;"  >
							<div class="main-menu">
							
								<!-- Brand and toggle get grouped for better mobile display -->
								<div class="navbar-header">
									<button type="button" class="navbar-toggle smooth-btn login" data-toggle="collapse" data-target=".navbar-collapse">
										<i class="fa fa-bars"></i>
									</button><!-- / button-->
								</div><!-- /.navbar-header-->
								<div class="collapse navbar-collapse navbar-expand">		  
									
									<ul class="nav navbar-nav navbar-right">
										<li :class="'smooth-menu '+($route.path=='/'?'active':'')" @click="()=>{ $router.push({ path:'/' }) }"><a >{{$t('Home')}}</a></li>
										<li :class="'smooth-menu '+($route.path=='/evisa'?'active':'')"  @click="()=>{ $router.push({ path:'evisa' }) }" ><a>{{$t('E Visa')}}</a></li>
										<li :class="'smooth-menu '+($route.path==''?'active':'')" ><a href="#pack">{{$t('Umrah Guide')}} </a></li>
										<li :class="'smooth-menu '+($route.path==''?'active':'')" ><a href="#spo">{{$t('Contact Us')}}</a></li>
										<li :class="'smooth-menu '+($route.path==''?'active':'')" ><a href="#spo">{{$t('Blogs')}}</a></li>
									</ul>
								</div><!-- /.navbar-collapse -->
								
							</div><!-- /.main-menu-->
							
						</div><!-- /.col-->
						<div >
							
							<div class="main-menu">
							
								<div class="navbar-expand">		  
									
									<ul class="nav navbar-nav navbar-right">
										
										<li @click="()=>{ if($i18n.locale=='ar'){ $i18n.locale='en'; } else{ $i18n.locale='ar'; } }" class="smooth-menu language"><a href="#spo"><i class="fas fa-globe"></i> {{$t(currentLocale.toLowerCase())}}</a></li>
										<li @click.stop class="ml-5"><a @click="toggleCart" class="smooth-btn cart" ><img src="./assets/images/home/cart.svg" /></a></li>
										<!-- <li class=""><a class="smooth-btn login" >Login</a></li> -->
										<li  class="" @click="()=>{ $router.push({ path:'/account/login' }) }" v-if="!loggedIn && !user && $route.path.toLowerCase()!='/account/login'"><a class="smooth-btn register" >{{$t('Login/Register')}}</a></li>
										<li class="pp-btn"  v-if="loggedIn && user"> <b-dropdown >
											<template #button-content>
												<img style="width: 30px; height: 30px; margin-right: 5px; border-radius: 15px; border: 2px solid white; box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;" src="./assets/images/client/testimonial1.jpg"/>    {{user.firstName}} {{user.lastName}}
											  </template>
											<b-dropdown-item href="#"><i class="fas fa-user"></i> &nbsp; My Profile</b-dropdown-item>
											<b-dropdown-item @click="logoutAccount"><i class="fas fa-sign-out-alt"></i> &nbsp; Log Out</b-dropdown-item>
										  </b-dropdown></li>
										<!--/.project-btn--> 
									</ul>
								</div><!-- /.navbar-collapse -->
								
							</div><!-- /.main-menu-->
							
						</div>
					</div><!-- /.row -->
					<div class="home-border"></div><!-- /.home-border-->
				</div><!-- /.container-->
			</div><!-- /.header-area -->
			<div v-if="$route.meta.isBookingPage" class="progress-container">
				<span :style="'width:'+bookingFormProgress+'%;'" class="progress-bar"></span>
			</div>
			<CartModel :isVisible="isShowCart"></CartModel>				

		</header><!-- /.top-area-->
		<!-- main-menu End -->
		
		<transition name="fade">
			<div :class="!$route.meta.admin?'main-body':''">
			
				<router-view  />
				
			</div>
		</transition>
		


		<!-- footer-copyright start -->
		<footer v-if="!$route.meta.admin && !$route.meta.isBookingPage"  class="footer-copyright">
			<div class="container">
                <div class="logo1">
                    <a href="index.html">
                        <img src="./assets/images/home/logo.png">
                    </a>
                </div><!-- /.logo-->
				<div class="footer-content">
                    
					<div class="row">

						<div class="col-sm-3">
							<div class="single-footer-item">
								<h2>Contact</h2>
								<div class="single-footer-txt">
									<p><a href="#">+44 740 325 3700</a></p>
									<p><a href="#">info@umrahwallet.co.uk</a></p>
								
								</div><!--/.single-footer-txt-->
							</div><!--/.single-footer-item-->

						</div><!--/.col-->

						<div class="col-sm-3">
							<div class="single-footer-item">
								<h2>Support</h2>
								<div class="single-footer-txt">
									<p><a href="#">Support Center</a></p>
									<p><a href="#">About us</a></p>
									<p><a href="#">FAQ</a></p>
								</div><!--/.single-footer-txt-->
							</div><!--/.single-footer-item-->

						</div><!--/.col-->

						<div class="col-sm-3">
							<div class="single-footer-item">
								<h2>Quick Links</h2>
								<div class="single-footer-txt">
									<p><a href="#">About</a></p>
									<p><a href="#">Services</a></p>
									<p><a href="#">Umrah</a></p>
									<p><a href="#">Hajj</a></p>
									
								</div><!--/.single-footer-txt-->
							</div><!--/.single-footer-item-->
						</div><!--/.col-->

						<div class="col-sm-3">
							<div class="single-footer-item text-center">
								<h2 class="text-left">Legals</h2>
								<div class="single-footer-txt text-left">
									<p>Privacy Policy</p>
									
									<p>Terms and Condition</p>
									<p>Cookies policy</p>
								</div><!--/.single-footer-txt-->
							</div><!--/.single-footer-item-->
						</div><!--/.col-->

					</div><!--/.row-->

				</div><!--/.footer-content-->
				<hr>
				<div class="foot-icons ">
					
		        	<p>Copyright &copy; 2023 Umrah Wallet</p>

		        </div><!--/.foot-icons-->
				<div id="scroll-Top">
					<i class="fa fa-angle-double-up return-to-top" id="scroll-top" data-toggle="tooltip" data-placement="top" title="" data-original-title="Back to Top" aria-hidden="true"></i>
				</div><!--/.scroll-Top-->
			</div><!-- /.container-->

		</footer><!-- /.footer-copyright-->
		<!-- footer-copyright end -->


	
	</div>

</template>
<script>

import { mapState, mapActions, mapMutations } from "vuex";

import {setCookie,getCookie,checkCookie,removeCookie}  from "./helpers/CookieHelpers.js"
import jQuery from "jquery";
import Vue from "vue";
import CartModel from "./components/Layout/CartModel.vue";
export default {
	name: "App",
	data() {
		return {
			isShowCart:false
		};
	},
	computed: {
		...mapState("cart", ["cartItems"]),		
		...mapState("account", ["user","loggedIn"]),
		currentLocale() {
			return this.$i18n.locale
		},

		bookingFormProgress:function(){
			if(this.$route.meta.isBookingPage==true && this.$route.params.process)
			{
				if(this.$route.params.process.toLowerCase()=='visa')
				{
					return 5;
				}
				if(this.$route.params.process.toLowerCase()=='journey')
				{
					return 10;
				}
				if(this.$route.params.process.toLowerCase()=='destination')
				{
					return 20;
				}
				if(this.$route.params.process.toLowerCase()=='traveller')
				{
					return 30;
				}
				if(this.$route.params.process.toLowerCase()=='flight')
				{
					return 40;
				}
				if(this.$route.params.process.toLowerCase()=='stay')
				{
					return 50;
				}
				if(this.$route.params.process.toLowerCase()=='packages'&& !this.$route.query.packageId)
				{
					return 60;
				}
				if(this.$route.params.process.toLowerCase()=='packages' && this.$route.query.packageId)
				{
					return 70;
				}
				if(this.$route.params.process.toLowerCase()=='custompackage'&& this.$route.query.page && this.$route.query.page.toLowerCase()=="flights")
				{
					return 65;
				}
				if(this.$route.params.process.toLowerCase()=='custompackage' && this.$route.query.page && this.$route.query.page.toLowerCase()=="stays" && this.$route.query.current=="0")
				{
					return 70;
				}
				if(this.$route.params.process.toLowerCase()=='custompackage' && this.$route.query.page && this.$route.query.page.toLowerCase()=="stays" && this.$route.query.current=="1")
				{
					return 75;
				}
				if(this.$route.params.process.toLowerCase()=='transport' && (!this.$route.query.itinerary ||this.$route.query.itinerary==1))
				{
					return 80;
				}				
				if(this.$route.params.process.toLowerCase()=='transport' && this.$route.query.itinerary && this.$route.query.itinerary==2)
				{
					return 80;
				}				
				if(this.$route.params.process.toLowerCase()=='transport' && this.$route.query.itinerary && this.$route.query.itinerary==3)
				{
					return 80;
				}

				if(this.$route.params.process.toLowerCase()=='ziyarat')
				{
					return 86;
				}
				
				if(this.$route.params.process.toLowerCase()=='review')
				{
					return 95;
				}
			}

			return 0;
		}
	},
	methods: {
		
		...mapActions("account", ["logout"]),
		...mapActions("cart", ["checkStorage"]),
		toggleCart(){
			this.isShowCart=!this.isShowCart;
		},
		logoutAccount(){
			this.logout();
		}
	},
	components: {
    CartModel,
    CartModel
},
	created() {
		document.getElementsByTagName("html")[0].lang = this.currentLocale;
		this.checkStorage();
	},
	mounted() {
	},
	watch: {
		cartItems:function(){
			this.toggleCart();
			
			localStorage.setItem("CartItem_Date",new Date());
			
			var json=JSON.stringify(this.cartItems);
			localStorage.setItem("CartItems",json);
		},
		currentLocale:function(){
			document.getElementsByTagName("html")[0].lang = this.currentLocale;
		},
		$route: {
            immediate: true,
            handler(to, from) {

				if(to.meta.auth==true && !this.loggedIn)
				{
					if(to.meta.admin==true)
					{
						this.$router.replace("/admin/login");
					}else{
						this.$router.replace("/account/login");
					}
					return;
				}

				if(to.meta.admin==true && to.meta.permissions && to.meta.permissions.length>0)
				{
					var permissions=to.meta.permissions;
					var userPermission = JSON.parse(localStorage.getItem("permissions"));
					var isPermit=false;
					permissions.forEach((p, i, arr) => {
						if (Object.values(userPermission).indexOf(p) >= 0) {
							isPermit = true;
						}
					});
					if(!isPermit)
					{
						this.$router.replace("/admin/permissionNotAssigned");
					}
				}

                document.title = "Umrah Wallet"+(to.meta.title?" - "+to.meta.title:"");
            }
        },
	},
};
</script>

<style scoped>

.smooth-btn{
    text-decoration: none;
    font-weight: 500;
    height: 42px;
    line-height: 40px;
    padding: 1rem 1.25rem !important;
    border-radius: 8px;
    line-height: 1.25;
    margin-right: 0px;
	cursor: pointer;
	font-weight: normal;
}
.smooth-btn.cart:hover{
	background-color: white;
	color: black;
    box-shadow: 0 3px 6px #d5d5d529;
}
.smooth-btn.register:hover{
	background-color: var(--primary-color);
	color: white !important;
}
.smooth-btn.cart{
	background-color: white;
	color: black;
    box-shadow: 0 3px 6px #d5d5d529;
}

.smooth-menu.language:hover{
	color: white !important;
	background-color: white;
}
.main-menu .nav .smooth-menu.language:hover:before{
    width: 0% !important;
}
.smooth-btn.login{
	background-color: #f8f8f8;
    border: 1px solid #e9e9e9; 
	color: black;
    box-shadow: 0 3px 6px #d5d5d529;
}
.smooth-btn.register{
	background-color: var(--primary-color);
	color: white !important;
}
.main-menu .nav .smooth-btn:hover:before{
    width: 0%;
}
.sticky-wrapper.is-sticky .main-menu .nav .smooth-btn.register{
	color: white  !important;
}
@media screen and (max-width: 991px)
{
.main-menu .nav li {
    padding-top: 5px;
    padding: 0px;
    padding-top: 9px;
    padding-left: 9px;
}
}
</style>
<style>

li.pp-btn li a{
	padding: 0px !important;
	color: black !important;
	font-weight: normal !important;
	
}

li.pp-btn li{
	padding: 0px !important;
	padding-top: 5px !important;
	padding-left: 15px!important;	
}

li.pp-btn li:hover{
	background-color: var(--primary-color);
	color: white !important;
}
li.pp-btn li:hover a{
	color: white !important;
}
li.pp-btn button{
	background: transparent !important;
	font-weight: bold;
}

li.pp-btn button:hover{
	color: white !important;
}

li.pp-btn button:focus{
	color: white !important;
}
</style>