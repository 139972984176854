import store from "./index";
import { transportService } from "../services/transport.service";
const state = {
	transports: null
};

const getters = {
};

const actions = {

	getTransports: async function ({ commit }) {
		var transports = await transportService.getTransports().then(
			(transports) => {
				if(transports.payload){
					
				
					state.transports=transports.payload;

				return transports.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return transports;
	},

	deleteTransport: async function ({ commit }, transportId) {
		var transports = await transportService.deleteTransport(transportId).then(
			(transports) => {
				if(transports.payload){
					
				

				return transports.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return transports;
	},
	saveTransport: async function ({ commit }, transport) {
		var self=this;
		var _transport = await transportService.saveTransport(transport).then(
			(transport) => {
					
				
			
				return transport.payload;
				
			},
			(error) => {
				return null;
			}
		);
		return _transport;
	},
	
	getTransportWithOptions: async function ({ commit }, transportId) {
		var transport = await transportService.getTransportOptions(transportId).then(
			(transport) => {
				if(transport.payload){
					
				
				return transport.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return transport;
	},
	saveTransportOption: async function ({ commit },{ transportId, transportOption }) {
		var self=this;
		
		var _transport = await transportService.saveTransportOption(transportId,transportOption).then(
			(transport) => {
					
				
			
				return transport.payload;
				
			},
			(error) => {
				return null;
			}
		);
		return _transport;
	},
	deleteTransportOption: async function ({ commit }, transportOptionId) {
		var transport = await transportService.deleteTransportOption(transportOptionId).then(
			(transport) => {
				if(transport.payload){
					
				

				return transport.payload;
				}
			},
			(error) => {
				return null;
			}
		);
		return transport;
	},
};

const mutations = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
  };