<template >
  <div>
    <div class="pagetitle" style="display: flex;">
      <h1 style="flex: 1;">Role Management</h1>
    </div><!-- End Page Title -->
    <div class="row mt-4">
      <div class="col-xl-3">

        <div v-for="role in roles" :class="'card ' + ($route.query.id == role.id ? 'active' : '')"
          >
          <div  @click="selectRole(role.id)" class="card-body profile-card pt-4 d-flex flex-column">
            <center><h3><b>{{ role.title }}</b></h3></center>     
          </div>
        </div>
        <div :class="'card '" >
          <div class="card-body profile-card pt-4 d-flex flex-column">
            <div @click="addOrEditRolePopup" class="card-body"
              style="cursor: pointer; flex: 1; width: 100%; height: 100%;  text-align: -webkit-center; ">
              <center class="" style="padding-top: 10%">
                <img style="width: 80px; height: 80px;" src="../../../assets/images/icons8-add-100.png" />
              </center>
            </div>
          </div>
        </div>

      </div>
      <div class="col-xl-8">
          <div class="card" v-if="selectedRole">
              <div class="card-body pt-4" >
                <RolePermission :roleData="selectedRole" @onUpdateRoles="getRoles()"></RolePermission>
              </div>
          </div>
      </div>

    </div>
     
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import Swal from 'sweetalert2';
import RolePermission from "./../../../components/Admin/role/RolePermission.vue"
export default {
  name: "RoleManagement",
  components: {
    RolePermission
  },
  data() {
    return {
      roles: [],
    };
  },
  computed: {
    selectedRole() {
            if (this.$route.query.id && this.roles) {
                return this.roles.find(x => x.id == this.$route.query.id);
            }
            return null;
    },
  },
  methods: {
    ...mapActions("roles", ["fetchRoles", "deleteRole", "addRole"]),
    async getRoles() {
      this.fetchRoles().then((res) => {
        if (res) {
          this.roles = res;
        }
      });
    },
    selectedRole() {
            if (this.$route.query.id && this.roles) {
                return this.roles.find(x => x.id == this.$route.query.id);
            }
            return null;
    },
    selectRole(id) {
      var self=this;
      self.$router.push({ query: { id: null } });
            setTimeout(() => {
                    
              self.$router.push({ query: { id: id } });
    
            }, 100);
          
    },
    async addOrEditRolePopup() {
      let html = `<input id="role" placeholder="Role" class="form-control mb-2"/>`;
      let self = this;
      const { value: formValues } = await Swal.fire({
        title: "Add Role",
        html: html,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "cancel",
        preConfirm: () => {
          return [
            document.getElementById("role").value
          ];
        }
      });
      if (formValues) {

        if (formValues.length == 1 && formValues[0]) {
          var obj = {
            title: formValues[0],
            IsActive: true,
            permissions: [],
          };
          self.addRole(obj)
            .then((res) => {
              if (res) {
                self.showMessage("success", "Role Added Successful");
                self.getRoles();
              }
            })
            .catch((err) => {
              self.showMessage("error", "Some error occurred");
            });
        }
      }
    },
    showMessage(status, message) {
      if (status == "success") {
        Vue.toasted.success(message, {
          theme: "outline",
          position: "bottom-center",
          duration: 5000,
        });
      } else if (status == "error") {
        Vue.toasted.error(message, {
          icon: "exclamation-triangle",
          theme: "outline",
          position: "bottom-center",
          singleton: true,
          duration: 3000,
        });
      }
    },

  },
  created() {
    this.getRoles();
    // this.addOrEditRolePopup();
  },
  watch: {

  },
};
</script>

<style scoped>
.card.active
{
  background-color: var(--primary-color);
}
.card.active h3{
  color: white;
}
</style>
